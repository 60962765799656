import React, { useState } from "react";
import {
  Typography,
  Box,
  Menu,
  MenuItem,
  CircularProgress,
  Avatar,
} from "@mui/material";
import TableComponent from "../../components/UI/others/TableComponent";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddCardIcon from "@mui/icons-material/AddCard";
import RemoveIcon from "@mui/icons-material/Remove";
import ConfirmModal from "../../components/UI/others/ConfirmModal";
import { useSelector, useDispatch } from "react-redux";
import styles from "./index.module.scss";
import { AppState, appActions } from "../../store/appState";
import { useNavigate } from "react-router-dom";
import { stockItem, stockItemColumns } from "../../interfaces/projects";
import { ConstructionService } from "../../store/projects/constructionService";
import { deleteRed, primaryColor, primaryGreen } from "../../assets/colors/colors";


const DashboardTable = () => {
  const [projectName, setProjectName] = useState("");
  const [projectId, setProjectId] = useState("");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const appState: AppState = useSelector((state: any) => state.app);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeProjectHandler = async () => {
    await ConstructionService.closeProject(projectId);
    dispatch(appActions.setUpdateNeeded(true));
  };

  const handleCloseAction = () => {
    handleClose();
    dispatch(appActions.setIsDeleting(true));
  };

  const handleDeleteAction = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowClickedHandler = (id: string, projectId: string, project: any) => {
    delete project.action;
    delete project.status;
    dispatch(appActions.setSelectedProject(project));
    // navigate(`construction`);
  };

  const createData = (
    id: string,
    name: string,
    measurement: string,
    savingStatus: any,
    quantity: number,
    stock: any
  ): stockItem => {
    return {
      id,
      measurement,
      name,
      stock,
      quantity,
      savingStatus: (
        <>
          <Avatar
            style={{
              height: 10,
              width: 10,
              display: "inline-block",
              marginRight: "5px",
              backgroundColor:
                savingStatus === "draft" ? deleteRed : primaryGreen,
            }}
          >
            {" "}
          </Avatar>
          {savingStatus.charAt(0).toUpperCase() + savingStatus.slice(1)}
        </>
      ),
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setProjectName(name);
            setProjectId(id);
            handleClick(event);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = appState?.stockItems?.map((item: any) =>
    createData(
      item._id,
      item.name,
      item.measurement,
      item.savingStatus,
      10,
      item.stock
    )
  );

  return (
    <>
      {!appState.isFetching && (
        <>
          <TableComponent
            rows={rows}
            columns={stockItemColumns}
            rowClickedHandler={rowClickedHandler}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />

          {open && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleCloseAction}>
                <AddIcon style={{ color: primaryColor }} />
                <Typography
                  style={{ color: primaryColor, paddingLeft: "10px" }}
                >
                  {" "}
                  Stock In
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <RemoveIcon style={{ color: deleteRed }} />
                <Typography style={{ color: deleteRed, paddingLeft: "10px" }}>
                  Stock Out
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseAction}>
                <AddCardIcon style={{ color: primaryColor }} />
                <Typography
                  style={{ color: primaryColor, paddingLeft: "10px" }}
                >
                  Payment
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ModeEditIcon style={{ color: primaryColor }} />
                <Typography
                  style={{ color: primaryColor, paddingLeft: "10px" }}
                >
                  Update
                </Typography>
              </MenuItem>
            </Menu>
          )}
          <ConfirmModal
            onConfirm={closeProjectHandler}
            content={`Are you sure you want to close ${projectName} project`}
          />
        </>
      )}
      {appState.isFetching && (
        <Box className={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default DashboardTable;
