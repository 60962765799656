import { Button, Box, CircularProgress, DialogActions } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { ChangeEventHandler } from "react";

export interface UploadExcelProps {
  open: boolean;
  close: Function;
  uploading: boolean;
  fileName: string;
  handleFileUpload: ChangeEventHandler<HTMLInputElement>;
  uploadHandler: Function;
}

const UploadExcelExpenses = (props: UploadExcelProps) => {
  let previewSource = false;

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.close()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="text-center">Upload Excel Expenses</DialogTitle>
        <DialogContent className="w-[30vw] h-[45vh]">
          <Box className="">
            {previewSource && (
              <Box className="">
                <img
                  className="h-[30vh] mx-auto"
                  src={previewSource}
                  alt="chosen"
                />
              </Box>
            )}
            {!previewSource && (
              <div className="bg-gray-100 h-[30vh] mb-3 flex items-center justify-center">
                <label htmlFor="contained-button-file">
                  <Button
                    startIcon={<AttachFileIcon />}
                    className=""
                    color="primary"
                    component="span"
                  >
                    {props.fileName === "" ? "Upload" : props.fileName}
                  </Button>
                </label>
              </div>
            )}
            {/* <input
                id="fileInput"
                type="file"
                name="image"
                onChange={handleFileInputChange}
                value={fileInputState}
                className={classes.image}
              /> */}
            <input
              accept=".xlsx, .xls"
              className=""
              onChange={props.handleFileUpload}
              id="contained-button-file"
              //   value={fileInputState}
              hidden
              type="file"
            />
            <Box className="flex justify-center">
              <div
                className="mx-2 cursor-pointer bg-gray-300 rounded-md border border-gray-400 mt-6 px-5 py-2"
                onClick={() => {}}
              >
                <CloseIcon />
              </div>
              {!props.uploading && (
                <div
                  id="submitButton"
                  className="mx-2 cursor-pointer bg-gray-300 rounded-md border border-gray-400 mt-6 px-5 py-2"
                  onClick={() => props.uploadHandler()}
                >
                  <CheckIcon />
                </div>
              )}
              {props.uploading && (
                <Button className="" disabled={true} type="submit">
                  <CircularProgress className="" />
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UploadExcelExpenses;
