import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TopActions from "../../components/UI/others/TopActions";
import DeleteIcon from "@mui/icons-material/Delete";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import NewClient from "./NewClient";
import { AppState, appActions } from "../../store/appState";
import { Client, clientColumns } from "../../interfaces/clients";
import TableComponent from "../../components/UI/others/TableComponent";
import styles from "../index.module.scss";
import Layout from "../../components/UI/others/Layout";
import HomeNavMenu from "../../components/NavMenu/HomeNavMenu";
import Storage from "../../utils/Storage";
import Visibility from "@mui/icons-material/Visibility";
import ConfirmModal from "../../components/UI/others/ConfirmModal";
import { User } from "../../interfaces/user";
import { deleteAction } from "../../store/app/globalActions";
import { deleteClientService } from "../../store/clients/clientsService";
import { getClients } from "../../store/clients/clientActions";

const Clients = (props: any) => {
  const token = Storage.getToken();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(50);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dispatch = useDispatch();
  const company = Storage.getUser().company._id;
  const [client, setClient] = useState({} as Client);
  const appState: AppState = useSelector((state: any) => state.app);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const user: User = Storage.getUser();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteClientHandler = () => {
    dispatch(
      deleteAction(deleteClientService, client._id, token, "Client", () =>
        dispatch(appActions.setFetchedClients(false))
      )
    );
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createData = (client: Client): any => {
    return {
      id: client._id,
      name: client.name,
      email: client.email ? client.email : "Not Set",
      contact: client.contact,
      tinNumber: client.tinNumber ? client.tinNumber : "Not Set",
      createdAt: client?.createdAt?.substring(0, 10),
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            handleClick(event);
            setClient(client);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = appState.clients?.map((client: any) =>
    createData(client)
  );

  useEffect(() => {
    console.log("Fetched clients changed ", appState.fetchedClients);
    if (!appState.fetchedClients) {
      dispatch(getClients(`?company=${company}`, token));
    }
  }, [appState.fetchedClients, company, dispatch, token]);

  return (
    <Layout
      title={`${user?.company?.name} CLIENTS`}
      navMenu={<HomeNavMenu select={5} />}
    >
      {!appState.isFetching && (
        <>
          <Box className={`${styles.center} ${styles.homeHeader}`}>
            <Typography className={styles.header}>Clients</Typography>
          </Box>
          <TopActions
            setNew={() =>
              dispatch(appActions.setOpenForm({ open: true, action: "add" }))
            }
            addNew={true}
            entity="Client"
          />
          {appState.openForm.open && <NewClient client={client} />}
          <TableComponent
            rows={rows}
            columns={clientColumns}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {open && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>
                <Visibility style={{ marginRight: "0.2rem" }} /> View
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(
                    appActions.setOpenForm({ open: true, action: "update" })
                  );
                }}
              >
                <AutorenewIcon />
                Update
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(appActions.setIsDeleting(true));
                }}
              >
                <DeleteIcon />
                Delete
              </MenuItem>
            </Menu>
          )}
        </>
      )}
      {appState.isFetching && (
        <Box className={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
      {appState.isDeleting && (
        <ConfirmModal
          onConfirm={deleteClientHandler}
          content={`Please confirm to delete ${client.name} client`}
        />
      )}
    </Layout>
  );
};

export default Clients;
