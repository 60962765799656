import { List } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PersonIcon from "@mui/icons-material/Person";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DescriptionIcon from "@mui/icons-material/Description";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import styles from "./index.module.scss";
import store from "store";
import { useDispatch } from "react-redux";
import NavItem from "./NavItem";
import { authActions } from "../../store/auth";

const HomeNavMenu = (props: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathName = location.pathname;

  const NavItems = [
    {
      label: "Projects",
      icon: <EngineeringIcon style={{ color: "currentColor" }} />,
      path: "/projects",
    },
    {
      label: "Reports",
      icon: <DescriptionIcon style={{ color: "currentColor" }} />,
      path: "/reports",
    },
    {
      label: "Expenses",
      icon: <MoneyOffIcon style={{ color: "currentColor" }} />,
      path: "/expenses",
    },
    {
      label: "Clients",
      icon: <PeopleAltIcon style={{ color: "currentColor" }} />,
      path: "/clients",
    },
    {
      label: "Account",
      icon: <PersonIcon style={{ color: "currentColor" }} />,
      path: "/account",
    },
    {
      label: "Users",
      icon: <RecentActorsIcon style={{ color: "currentColor" }} />,
      path: "/users",
    },
    {
      label: "Logout",
      icon: <ExitToAppIcon style={{ color: "currentColor" }} />,
      path: "/login",
    },
  ];
  return (
    <List>
      {NavItems.map((nav, index) => (
        <Link
          key={index}
          to={nav.path}
          className={styles.link}
          onClick={() => {
            if (nav.label === "Logout") {
              store.remove("x-auth-token");
              store.remove("user");
              window.location.reload();
              dispatch(authActions.logout());
            }
          }}
        >
          <NavItem
            nav={nav}
            selected={pathName.includes(nav.path.split("/")[2])}
          />
        </Link>
      ))}
    </List>
  );
};

export default HomeNavMenu;
