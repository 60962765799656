import axios from "axios";
import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getDocumentsService = async (query: string, token: string) => {
  return await HttpRequest.get(
    `${SERVER_URL}/construction/document${query}`,
    token
  );
};

export const getDocumentsStatsService = async (
  query: string,
  token: string
) => {
  return await HttpRequest.get(
    `${SERVER_URL}/construction/document/stats${query}`,
    token
  );
};

export const addDocumentService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/project/Projects`, data, token);
};

export const uploadDocumentService = async (data: any, token: string) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${SERVER_URL}/construction/document`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data,
    });

    return res.data;
  } catch (err: any) {
    console.log(err.response.data);
    return err;
  }
};

export const uploadImagesService = async (data: any, token: string) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${SERVER_URL}/construction/document/images`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data,
    });

    return res.data;
  } catch (err: any) {
    console.log(err.response.data);
    return err;
  }
};

export const deleteDocumentService = async (
  documentId: string,
  token: string
) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/construction/document/${documentId}`,
    token
  );
};
