import React, { useState } from "react";
import {
  Typography,
  Box,
  Menu,
  MenuItem,
  CircularProgress,
  Avatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useSelector, useDispatch } from "react-redux";
import styles from "../index.module.scss";
import store from "store";
import { appActions } from "../../store/appState";
import { useNavigate } from "react-router-dom";
import { numberWithCommas } from "../../utils/functions";
import ProjectView from "./ProjectView";
import Storage from "../../utils/Storage";
import ConfirmClose from "./ConfirmClose";
import NewProject from "./NewProject";
import { useTranslation } from "react-i18next";
import { Project } from "../../interfaces/project";
import { deleteRed, primaryGreen } from "../../assets/colors/colors";
import { projecTabletData, projectColumns } from "../../interfaces/projects";
import TableComponent from "../../components/UI/others/TableComponent";
import { deleteAction, updateAction } from "../../store/app/globalActions";
import {
  closeProjectService,
  deleteConsProjectService,
} from "../../store/projects/projectsService";
import ConfirmModal from "../../components/UI/others/ConfirmModal";
import { TableProps } from "../../interfaces";

const DashboardTable = (props: TableProps) => {
  const [project, setProject] = useState({} as Project);
  const [projectViewOpen, setProjectViewOpen] = useState(false);
  const { count, page, setPage, rowsPerPage, setRowsPerPage } = props;
  const [closeProject, setCloseProject] = useState(false);
  const appState = useSelector((state: any) => state.app);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Storage.getToken();
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteProjectHandler = async () => {
    dispatch(
      deleteAction(
        deleteConsProjectService,
        project._id,
        token,
        "Project",
        () => {
          dispatch(appActions.setUpdateNeeded(true));
        }
      )
    );
  };

  const closeProjectHandler = async () => {
    dispatch(
      updateAction(
        closeProjectService,
        project._id,
        { projectName: project?.name, status: "closed" },
        token,
        () => {
          setCloseProject(false);
          dispatch(appActions.setFetchedProjects(false));
        }
      )
    );
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(appActions.setFetchedProjects(false));
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(appActions.setFetchedProjects(false));
  };

  const rowClickedHandler = (id: string, projectId: string, project: any) => {
    delete project.action;
    delete project.status;
    project.name = project.projectName;
    delete project.projectName;
    store.set("project", project);
    dispatch(appActions.resetAppState());
    navigate(`/project/expenses`);
  };

  const createData = (project: Project): projecTabletData => {
    return {
      _id: project._id,
      name: (
        <div className="w-[27vw]">
          <Typography className="truncate">{project.name}</Typography>
        </div>
      ),
      projectName: project.name,
      client: project.client?.name,
      startDate: project.startDate.substring(0, 10),
      po: numberWithCommas(project?.contractAmount),
      status: (
        <>
          <Avatar
            style={{
              height: 10,
              width: 10,
              display: "inline-block",
              marginRight: "5px",
              backgroundColor:
                project.status === "closed" ? deleteRed : primaryGreen,
            }}
          >
            {" "}
          </Avatar>
          {project.status.charAt(0).toUpperCase() + project.status.slice(1)}
        </>
      ),
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setProject(project);
            handleClick(event);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = appState?.projects?.map((project: Project) =>
    createData(project)
  );

  return (
    <>
      {!appState.isFetching && (
        <>
          <TableComponent
            rows={rows}
            columns={projectColumns}
            rowClickedHandler={rowClickedHandler}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {open && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(
                    appActions.setOpenForm({ open: true, action: "update" })
                  );
                }}
              >
                <AutorenewIcon />
                <Typography>{t("update")}</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setCloseProject(true);
                }}
              >
                <CloseIcon />
                <Typography>Close</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(appActions.setIsDeleting(true));
                }}
              >
                <DeleteIcon />
                <Typography>{t("delete")}</Typography>
              </MenuItem>
            </Menu>
          )}
          {appState.isDeleting && (
            <ConfirmModal
              onConfirm={deleteProjectHandler}
              content={`Are you sure you want to delete ${project.name} project`}
            />
          )}
          {closeProject && (
            <ConfirmClose
              onConfirm={closeProjectHandler}
              open={closeProject}
              close={() => setCloseProject(false)}
              content={`Are you sure you want to close ${project.name} project`}
            />
          )}
          {projectViewOpen && (
            <ProjectView open={projectViewOpen} setOpen={setProjectViewOpen} />
          )}
        </>
      )}
      {appState.openForm.open && (
        <NewProject
          project={project}
          open={appState.openForm.open}
          close={() => {
            dispatch(appActions.setOpenForm({ open: false, action: "" }));
          }}
        />
      )}
      {appState.isFetching && (
        <Box className={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default DashboardTable;
