import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getCompanyExpenseService = async (query: any, token: string) => {
  return await HttpRequest.get(
    `${SERVER_URL}/project/companyexpenses${query}`,
    token
  );
};

export const getCompanyExpenseStatsService = async (
  query: any,
  token: string
) => {
  return await HttpRequest.get(
    `${SERVER_URL}/project/companyexpenses/stats${query}`,
    token
  );
};

export const getExpenseCategoriesService = async (
  query: any,
  token: string
) => {
  return await HttpRequest.get(
    `${SERVER_URL}/project/expensecategory${query}`,
    token
  );
};

export const searchCompanyExpenseService = async (
  query: any,
  token: string
) => {
  return await HttpRequest.get(
    `${SERVER_URL}/project/companyexpenses/search${query}`,
    token
  );
};

export const addCompanyExpenseService = async (data: any, token: string) => {
  return await HttpRequest.post(
    `${SERVER_URL}/project/companyexpenses`,
    data,
    token
  );
};

export const updateCompanyExpenseService = async (
  expenseId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/project/companyexpenses/${expenseId}`,
    data,
    token
  );
};

export const deleteCompanyExpenseService = async (
  id: String,
  token: string
) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/project/companyexpenses/${id}`,
    token
  );
};
