import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import Storage from "../../utils/Storage";
import GenericForm from "../../components/UI/Forms/GenericForm";
import { appActions } from "../../store/appState";
import { addAction } from "../../store/app/globalActions";
import { uploadDocumentService } from "../../store/documents/documentsService";

export default function NewDocument(props: any) {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [type, setType] = useState({}) as any;
  const appState = useSelector((state: any) => state.app);
  const project = Storage.getProject()._id;
  const nameRef = useRef<HTMLInputElement>(null);
  const token = Storage.getToken();
  const [selectedFile, setSelectedFile] = useState(null as unknown);
  const [uploading, setUploading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  let typeValues = [
    {
      name: "Purchase Order",
    },
    {
      name: "Contract",
    },
    {
      name: "Letter",
    },
    {
      name: "Invoice",
    },
    {
      name: "Other",
    },
  ];

  const handleTypeChange = (event: any) => {
    setType(event.target.value as string);
  };

  const handleFileInputChange = (e: any) => {
    // @ts-ignore: Object is possibly 'null'.
    const file = (e.target as HTMLInputElement).files[0];
    setSelectedFile(file);
  };

  const newDocumentElements = [
    {
      label: "Document Name",
      identifier: "name",
      inputComponent: "input",
      placeholder: "Enter Item Name",
      required: true,
      register,
      errors,
      validationObject: {
        required: "Required field",
      },
    },
    {
      label: "Document Type",
      inputComponent: "select",
      identifier: "type",
      value: type,
      values: typeValues,
      setValue: setType,
      id: "name",
      name: "name",
      onChanged: (value: any) => {
        setType(value);
      },
    },
    {
      label: "File",
      identifier: "file",
      inputComponent: "file",
      type: "file",
      register,
      errors,
      placeholder: "Choose File",
      validationObject: {
        onChange: handleFileInputChange,
      },
    },
  ];

  const handleSubmitFile = handleSubmit(async (data: any) => {
    try {
      if (!selectedFile) return;
      let formdata = new FormData();
      formdata.append("file", selectedFile as File);
      formdata.append("name", data.name);
      formdata.append("type", type.name);
      formdata.append("project", project);
      dispatch(
        addAction(uploadDocumentService, formdata, token, () => {
          props.close();
          dispatch(appActions.setFetchedDocuments(false));
        })
      );
    } catch (error: any) {
      console.log(error.response.data.message);
    }
  });

  return (
    <GenericForm
      title="Upload New Document"
      onSubmit={handleSubmitFile}
      formElements={newDocumentElements}
      style="vertical"
      open={appState.openForm.open}
      close={() => {
        dispatch(appActions.setOpenForm({ open: false, action: "" }));
      }}
    />
  );
}
