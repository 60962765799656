import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import Storage from "../../utils/Storage";
import { appActions } from "../../store/appState";
import { useForm } from "react-hook-form";
import GenericForm from "../../components/UI/Forms/GenericForm";
import { addAction } from "../../store/app/globalActions";
import { uploadImagesService } from "../../store/documents/documentsService";

export default function NewImage(props: any) {
  const dispatch = useDispatch();
  const project = Storage.getProject()._id;
  const nameRef = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState([] as any);
  const [imageType, setImageType] = useState({} as any);
  const token = Storage.getToken();
  const appState = useSelector((state: any) => state.app);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFileInputChange = (e: any) => {
    // @ts-ignore: Object is possibly 'null'.
    const files = (e.target as HTMLInputElement).files;
    // previewFile(file);
    setSelectedFiles(files);
    console.log((e.target as HTMLInputElement).files);
  };

  const handleSubmitFile = handleSubmit(async (data: any) => {
    try {
      if (selectedFiles.length === 0) return;
      let formdata = new FormData();
      // formdata.append('file', selectedFiles[0]);

      for (let i = 0; i < selectedFiles.length; i++) {
        formdata.append("files", selectedFiles[i]);
      }
      if (nameRef.current?.value)
        formdata.append("name", nameRef.current?.value);
      formdata.append("type", "image");
      formdata.append("imageType", imageType?.id);
      formdata.append("isImage", JSON.stringify(true));
      formdata.append("project", project);
      dispatch(
        addAction(uploadImagesService, formdata, token, () => {
          props.close();
          dispatch(appActions.setFetchedImages(false));
        })
      );
    } catch (error: any) {
      console.log(error);
    }
  });

  let statusValues = [
    {
      id: "before",
      name: "Before",
    },
    {
      id: "in_progress",
      name: "In Progress",
    },
    {
      id: "after",
      name: "After",
    },
  ];

  const newImageForm = [
    {
      label: "File",
      identifier: "file",
      inputComponent: "file",
      type: "file",
      register,
      errors,
      placeholder: "Choose File",
      validationObject: {
        onChange: handleFileInputChange,
      },
    },
    {
      label: "Status",
      identifier: "imageType",
      inputComponent: "select",
      values: statusValues,
      register,
      errors,
      onChanged: (status: any) => setImageType(status),
      value: imageType,
      id: "id",
      name: "name",
    },
  ];

  return (
    <GenericForm
      title="Upload Site Image"
      onSubmit={handleSubmitFile}
      formElements={newImageForm}
      style="vertical"
      open={appState.openForm.open}
      close={() => {
        dispatch(appActions.closeForm());
      }}
    />
  );
}
