import { Box, Typography, Avatar } from '@mui/material';
import styles from '../../index.module.scss';
import FileInput from './FileInput';
import FormCheckBox from './FormCheckBox';
import FormDatePicker from './FormDatePicker';
import FormDatePicker2 from './FormDatePicker2';
import FormInput from './FormInput';
import FormInput2 from './FormInput2';
import FormSelect2 from './FormSelect2';
import FormSelect3 from './FormSelect3';
import { deleteRed } from '../../../assets/colors/colors';

const FormInputs = (props: any) => {
  return (
    <Box className={props?.style === 'horizontal' ? styles.forms_input_horizontal : styles.forms_input_vertical}>
      <Box className={styles.display_flex}>
        <Typography className={styles.label}>{props?.element.label}</Typography>
        {props?.element.required && (
          <Avatar
            style={{
              height: 5,
              width: 5,
              display: 'inline-block',
              marginRight: '5px',
              backgroundColor: deleteRed,
              // backgroundColor: status === 'closed' ? deleteRed : primaryGreen,
            }}
          >
            {' '}
          </Avatar>
        )}
      </Box>

      {props?.element.inputComponent === 'input' && (
        <FormInput element={props?.element} style={props?.style === 'vertical' ? 'block' : 'iniline'} />
      )}
      {props?.element.inputComponent === 'input2' && (
        <FormInput2 element={props?.element} style={props?.style === 'vertical' ? 'block' : 'iniline'} />
      )}
      {props?.element.inputComponent === 'select' && <FormSelect2 element={props?.element} />}
      {props?.element.inputComponent === 'select3' && <FormSelect3 element={props?.element} />}
      {props?.element.inputComponent === 'date' && (
        <FormDatePicker
          label="Start Date"
          element={props?.element}
          placeholder="Provice Start Date"
          style={props?.style === 'vertical' ? 'block' : 'iniline'}
        />
      )}
      {props?.element.inputComponent === 'date2' && (
        <FormDatePicker2
          label="Start Date"
          element={props?.element}
          placeholder="Provice Start Date"
          style={props?.style === 'vertical' ? 'block' : 'iniline'}
        />
      )}
      {props?.element.inputComponent === 'checkbox' && <FormCheckBox element={props?.element} />}
      {props?.element.inputComponent === 'file' && <FileInput element={props?.element} />}
    </Box>
  );
};

export default FormInputs;
