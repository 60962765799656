import { Box, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../index.module.scss';
import { getPayroll } from '../../store/documents/documentActions';

const SiteEmployees = () => {
    const dispatch = useDispatch();

  const projectId = useSelector((state: any) => state.app.projectId);
    var curr = new Date(); // get current date
  var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
  var last = first + 6; // last day is the first day + 6

  var firstdayFormated = new Date(curr.setDate(first)).toISOString().split('T')[0];
  var lastdayFormated = new Date(curr.setDate(last)).toISOString().split('T')[0];
  var firstday = new Date(curr.setDate(first)).toUTCString();
  var lastday = new Date(curr.setDate(last)).toUTCString();

  useEffect(() => {
    dispatch(
      getPayroll(
        `?_id=${projectId}&fields=employees`,
        `project=${projectId}&date[gte]=${firstdayFormated}&date[lte]=${lastdayFormated}`
      )
    );
  }, [dispatch, firstdayFormated, lastdayFormated, projectId]);
  
  return (
    <>
      <Typography className={styles.header}>This Week's Payroll / Liste de Paie</Typography>
      <Typography className={styles.subHeader}>
        {firstday.substring(5, 16)} - {lastday.substring(5, 16)}
      </Typography>
      <Box className={styles.payrollContainer}>
        <Grid container className={styles.payrollItem} spacing={1}>
          <Grid item style={{ width: '35px', backgroundColor: 'orangeRed' }}>
            Nbr
          </Grid>
          <Grid item xs={2}>
            Name
          </Grid>
          <Grid item xs={2}>
            Id
          </Grid>
          <Grid item xs>
            Job
          </Grid>
          <Grid item xs>
            24th
          </Grid>
          <Grid item xs>
            25th
          </Grid>
          <Grid item xs>
            26th
          </Grid>
          <Grid item xs>
            27th
          </Grid>
          <Grid item xs>
            28th
          </Grid>
          <Grid item xs>
            29th
          </Grid>
          <Grid item xs>
            30th
          </Grid>
        </Grid>

        <Grid className={styles.payrollItem} container spacing={1}>
          <Grid item style={{ width: '35px', backgroundColor: 'orangeRed' }}>
            1
          </Grid>
          <Grid item xs={2}>
            Eric TURATSINZE
          </Grid>
          <Grid item xs={2}>
            1199980022983155
          </Grid>
          <Grid item xs>
            MAÇON
          </Grid>
          <Grid item xs>
            <Box className={styles.payrollCell}></Box>
          </Grid>
          <Grid item xs>
            <Box className={styles.payrollCell}></Box>
          </Grid>
          <Grid item xs>
            <Box className={styles.payrollCell}></Box>
          </Grid>
          <Grid item xs>
            <Box className={styles.payrollCell}></Box>
          </Grid>
          <Grid item xs>
            <Box className={styles.payrollCell}></Box>
          </Grid>
          <Grid item xs>
            <Box className={styles.payrollCell}></Box>
          </Grid>
          <Grid item xs>
            <Box className={styles.payrollCell}></Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SiteEmployees;
