import { useState, useEffect } from "react";
import {
  Button,
  Box,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import styles from "../index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import NewImage from "./NewImage";
import Storage from "../../utils/Storage";
import LoadingContainer from "../../components/UI/others/LoadingContainer";
import NoData from "../../components/UI/others/NoData";
import FolderIcon from "@mui/icons-material/Folder";
import { ListItem, ListItemText, ListItemIcon, List } from "@mui/material";
import { primaryGreen, textColor } from "../../assets/colors/colors";
import { AppState, appActions } from "../../store/appState";
import Layout from "../../components/UI/others/Layout";
import ConstructionNavMenu from "../../components/NavMenu/ConstructionNavMenu";
import ImageView from "./ImageView";
import { getImages } from "../../store/documents/documentActions";

const ConstructionImages = () => {
  const [selected, setSelected] = useState(0);
  const [init, setInit] = useState(true);
  const [query, setQuery] = useState("");
  const appState: AppState = useSelector((state: any) => state.app);
  const [viewImage, setViewImage] = useState(false);
  const [image, setImage] = useState(false);
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const project = Storage.getProject()._id;

  useEffect(() => {
    if (!init) {
      if (listItems[selected].id) {
        setQuery(`&imageType=${listItems[selected].id}`);
      } else {
        setQuery("");
      }
      dispatch(appActions.setFetchedImages(false));
    }
    setInit(false);
  }, [selected]);

  useEffect(() => {
    if (!appState.fetchedImages)
      dispatch(getImages(`?project=${project}&isImage=true${query}`, token));
  }, [
    appState.updateNeeded,
    appState.fetchedImages,
    dispatch,
    project,
    token,
  ]);

  const listItems = [
    { name: "All", id: "" },
    { name: "Before", id: "before" },
    { name: "In Progress", id: "in_progress" },
    { name: "After", id: "after" },
  ];

  const downloadAllImagesHandler = async () => {
    await downloadImage(appState?.images[0]?.url);
  };

  // Function to download an image
  const downloadImage = async (url: string) => {
    const link = document.createElement("a");
    link.download = url;

    link.href = url;

    link.click();
    // try {
    //   const response = await fetch(url);
    //   const blob = await response.blob();
    //   // You can save the image using the blob, or perform other actions here
    //   console.log('Downloaded image:', url);
    // } catch (error) {
    //   console.error('Error downloading image:', url, error);
    // }
  };

  return (
    <Layout navMenu={<ConstructionNavMenu select={5} />}>
      <Typography className={styles.navTitle}>{`${
        Storage.getProject().name
      } • Images`}</Typography>
      <Box className={styles.aRight}>
        <Button
          variant="contained"
          onClick={() =>
            dispatch(appActions.setOpenForm({ open: true, action: "add" }))
          }
        >
          Upload Images
        </Button>
        <Button className="ms-4" variant="contained" onClick={() => {}}>
          Download All Images
        </Button>
      </Box>
      <List style={{ display: "flex", width: "37rem" }}>
        {listItems.map((item: any, index: number) => (
          <ListItem
            selected={selected === index}
            onClick={() => setSelected(index)}
            classes={{
              root: styles.folder_root,
              selected: styles.folder_listItemSelected,
            }}
          >
            <ListItemIcon
              style={{
                color: selected === index ? primaryGreen : textColor,
                minWidth: "22px",
              }}
            >
              <FolderIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{item.name}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Box className={styles.galleryContainer}>
        {appState.isFetching && <LoadingContainer />}
        {!appState.isFetching && (
          <>
            <ImageList rowHeight={180} className={styles.imageList} cols={4}>
              {appState?.images?.length > 0 &&
                appState?.images?.map((image: any, index: any) => (
                  <ImageListItem
                    key={image.cloudinary_id + index}
                    cols={1}
                    style={{ overflow: "hidden", cursor: "pointer" }}
                    onClick={() => {
                      setImage(image);
                      setViewImage(true);
                    }}
                  >
                    <img src={image.url} alt={image.cloudinary_id} />
                  </ImageListItem>
                ))}
            </ImageList>
            {viewImage && (
              <ImageView
                image={image}
                open={viewImage}
                close={() => setViewImage(false)}
              />
            )}
          </>
        )}
        {!appState.isFetching && appState?.images?.length === 0 && (
          <NoData />
        )}
      </Box>
      {appState.openForm.open && (
        <NewImage
          open={appState.openForm.open}
          close={() => dispatch(appActions.closeForm())}
        />
      )}
    </Layout>
  );
};

export default ConstructionImages;
