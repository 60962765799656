import { useEffect } from "react";
import { Grid, Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import store from "store";
import { authActions } from "../../../store/auth";

import logo from "../../../assets/images/logocems.png";
import styles from "../../../pages/index.module.scss";
import AppBar from "./AppBar";

const Layout = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authToken = store.get("x-auth-token");
  const authUser = store.get("user");

  const authState = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (!authToken || !authUser) {
      navigate("/login");
    } else if (!authState.isAuth) {
      dispatch(
        authActions.login({
          token: authToken,
          data: { user: authUser },
          status: 200,
        })
      );
    }
  }, [dispatch, authState, authToken, authUser, navigate]);

  return (
    <>
      {(!authToken || !authUser || !authState.isAuth) && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid container className={styles.container} spacing={0}>
        <Grid className={styles.sidebar} item xs={2}>
          <div className={`${styles.center} ${styles.imageContainer}`}>
            <img className={styles.image} alt="logo" src={logo} />
          </div>
          {/* <ConstructionNavMenu select={props.select} /> */}

          {props.navMenu}
        </Grid>
        <Grid className={styles.main} item xs={10}>
          <AppBar pages={props?.pages} title={props?.title} />
          <div className="px-12">{props.children}</div>
        </Grid>
      </Grid>
    </>
  );
};

export default Layout;
