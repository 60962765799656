import { Box, Skeleton, TextField } from '@mui/material';
import styles from '../../index.module.scss';

const FormInput = (props: any) => {
  return (
    <>
      {props?.element?.loading && (
        <Box>
          <Skeleton className={`${styles.form_input} ${styles.form_select_skeleton}`} />
        </Box>
      )}
      {!props?.element?.loading && (
        <TextField
          placeholder={props?.element?.placeholder}
          className={styles.form_input}
          inputProps={{
            className: styles.textField_input,
          }}
          defaultValue={props?.element?.defaultValue}
          type={props?.element?.type === 'number' ? 'number' : 'text'}
          step={0.01}
          {...props?.element?.register(props?.element?.identifier, props?.element?.validationObject)}
          error={props?.element?.errors[`${props?.element?.identifier}`] !== undefined}
          helperText={
            props?.element?.errors[`${props?.element?.identifier}`]
              ? props?.element?.errors[`${props?.element?.identifier}`].message
              : null
          }
          disabled={props?.element?.disabled ? true : false}
        />
      )}
    </>
  );
};

export default FormInput;
