import { useEffect, useState } from "react";
import {
  Box,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import styles from "../index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import pdf_logo from "../../assets/images/pdf.jpg";
import NewDocument from "./NewDocument";
import Storage from "../../utils/Storage";
import LoadingContainer from "../../components/UI/others/LoadingContainer";
import NoData from "../../components/UI/others/NoData";
import ConfirmModal from "../../components/UI/others/ConfirmModal";
import { SelectedElement } from "../../interfaces";
import { AppState, appActions } from "../../store/appState";
import { getDocuments } from "../../store/documents/documentActions";
import { deleteAction } from "../../store/app/globalActions";
import { deleteDocumentService } from "../../store/documents/documentsService";

const Files = () => {
  const appState: AppState = useSelector((state: any) => state.app);
  const [document, setDocument] = useState({} as SelectedElement);
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const project = Storage.getProject()._id;

  useEffect(() => {
    if (!appState.fetchedDocuments) {
      dispatch(
        getDocuments(
          `?project=${project}&isImage=false&type[ne]=payroll`,
          token,
          () => dispatch(appActions.setFetchedDocuments(true))
        )
      );
    }
  }, [
    appState.updateNeeded,
    dispatch,
    project,
    token,
    appState.fetchedDocuments,
  ]);

  const deleteDocumentHandler = () => {
    dispatch(
      deleteAction(deleteDocumentService, document.id, token, "Document", () =>
        dispatch(appActions.setFetchedDocuments(false))
      )
    );
  };

  return (
    <>
      <>
        <Box className={styles.aRight}>
          <Button
            style={{ marginBottom: "1rem" }}
            variant="contained"
            onClick={() =>
              dispatch(appActions.setOpenForm({ open: true, action: "add" }))
            }
          >
            Upload Document
          </Button>
        </Box>
        <Box className={styles.galleryContainer}>
          {!appState.isFetching && (
            <TableContainer component={Paper}>
              <Table
                className={styles.table}
                classes={{ root: styles.customTable }}
                size="small"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">DOCUMENT NAME</TableCell>
                    <TableCell align="center">DOCUMENT Type</TableCell>
                    <TableCell align="center">File Type</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                {appState?.documents?.length > 0 && (
                  <TableBody>
                    {appState?.documents.map(
                      (row: any, index: number) => (
                        <TableRow key={row.date + index}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">{row?.name}</TableCell>
                          <TableCell align="center">{row?.type}</TableCell>
                          <TableCell align="center">
                            <img
                              style={{ height: "2rem" }}
                              alt="file_type"
                              src={pdf_logo}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Box className={styles.poActions}>
                              <a
                                className={styles.link}
                                href={row.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <VisibilityIcon
                                  style={{ marginRight: "10px" }}
                                />
                              </a>
                              <a
                                className={styles.link}
                                href={row.url}
                                target="_blank"
                                download
                              >
                                <CloudDownloadIcon />
                              </a>
                              <DeleteIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setDocument({
                                    name: row?.name,
                                    id: row?._id,
                                  });
                                  dispatch(appActions.setIsDeleting(true));
                                }}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          )}
          {!appState.isFetching &&
            appState?.documents?.length === 0 && <NoData />}
          {appState.isFetching && <LoadingContainer />}
        </Box>
      </>

      {appState.openForm.open && (
        <NewDocument
          open={appState.openForm.open}
          close={() => dispatch(appActions.closeForm())}
        />
      )}
      {appState.isDeleting && (
        <ConfirmModal
          onConfirm={deleteDocumentHandler}
          content={`Please confirm to delete ${document.name} document`}
        />
      )}
    </>
  );
};

export default Files;
