import { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useSelector, useDispatch } from "react-redux";
import { AppState, appActions } from "../../store/appState";
import { useNavigate } from "react-router-dom";
import { authActions } from "../../store/auth";
import TopActions from "../../components/UI/others/TopActions";
import Filter from "./Filter";
import DashboardTable from "./DashboardTable";
import Storage from "../../utils/Storage";
import { User } from "../../interfaces/user";
import { ChipData } from "../../interfaces/constants";
import { Project } from "../../interfaces/project";
import {
  getConsProjects,
  searchConsProjects,
} from "../../store/projects/projectsActions";

const Home = (props: any) => {
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [chipData, setChipData] = useState([]);
  const [query, setQuery] = useState("");
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const appState: AppState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const user: User = Storage.getUser();
  const filterHandler = (filterData: any) => {
    setChipData(filterData);
  };

  useEffect(() => {
    if (!appState.isFetching) {
      setCount(appState.count);
    }
  }, [appState.isFetching, appState.count]);

  useEffect(() => {
    console.log("2", chipData);
    let queryString = '';
    chipData.forEach((data: any) => {
      queryString += `&${data.id}=${data.filter}`;
    });
    setQuery(queryString);
    dispatch(appActions.setFetchedProjects(false));
  }, [chipData]);

  const handleDelete = (chipToDelete: ChipData) => () => {
    setChipData((chips) =>
      chips.filter((chip: ChipData) => chip.key !== chipToDelete.key)
    );
  };

  const createExportableData = (
    name: string,
    client: string,
    startDate: string,
    po: number,
    status: string
  ): any => {
    return {
      name,
      client,
      startDate,
      po,
      status,
    };
  };

  const data = appState?.projects?.map((project: Project) =>
    createExportableData(
      project.name,
      project?.client?.name,
      project?.startDate.substring(0, 10),
      project.contractAmount,
      project?.status
    )
  );

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(searchConsProjects(`?text=${searchText}`, token));
        setIsInit(false);
      } else {
        if (!appState.isFetching && appState.fetchedProjects && !isInit) {
          dispatch(appActions.setFetchedProjects(false));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, searchText, token]);

  useEffect(() => {
    if (!token) {
      dispatch(authActions.logout());
      return navigate("/login");
    }
    if (!appState.fetchedProjects) {
      dispatch(
        getConsProjects(
          `?page=${page}&limit=${rowsPerPage}${query}&company=${user?.company?._id}`,
          token
        )
      );
    }
  }, [
    dispatch,
    page,
    token,
    query,
    navigate,
    rowsPerPage,
    appState.fetchedProjects,
  ]);

  return (
    <>
      {/* <Grid container spacing={2}>
        <Grid item xs={9}>
        </Grid>
        <Grid item xs={3}>
        </Grid>
      </Grid> */}
      {/* <Greetings /> */}

      <TopActions
        setNew={() =>
          dispatch(appActions.setOpenForm({ open: true, action: "add" }))
        }
        data={data}
        entity="Project"
        setFilter={setShowFilter}
        setSearchText={setSearchText}
        filter={true}
        addNew={true}
      />
      {showFilter && (
        <Filter
          filterHandler={filterHandler}
          open={showFilter}
          setOpen={setShowFilter}
        />
      )}
      {chipData.length > 0 && (
        <Stack
          direction="row"
          spacing={1}
          style={{ marginTop: "1rem", marginBottom: "1.5rem" }}
        >
          {chipData.map((data: any) => {
            const value = data?.value ? data.value : data.filter;
            return (
              <Chip
                key={data.key}
                label={`${data.label}: ${value}`}
                onDelete={
                  data.label === "React" ? undefined : handleDelete(data)
                }
              />
            );
          })}
        </Stack>
      )}
      <DashboardTable
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};

export default Home;
