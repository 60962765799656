import { appActions } from "../appState";
import { setResponseAction } from "../../utils/functions";
import { getReportsService } from "./reportService";

export const getReportsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getReportsService(query, token);
      if (
        res.response?.data.status === "fail" ||
        res.response?.data.status === "error"
      ) {
        dispatch(setResponseAction(res));
      } else {
        dispatch(appActions.setReports(res.data));
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(appActions.setFetchedReports(true));
      }
      dispatch(appActions.setUpdateNeeded(false));
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log("error: ", err);
    }
  };
};
