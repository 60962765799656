import { Box } from '@mui/material';
import styles from '../../index.module.scss';
import Skeleton from '@mui/material/Skeleton';
import Select from 'react-select';
import { useSelector } from 'react-redux';

const FormSelect2 = (props: any) => {
  const appState = useSelector((state: any) => state.app);

  return (
    <>
      {/* <Autocomplete
        value={props?.element?.value}
        onChange={(event: any, newValue: any) => {
          if(props.element.onChanged)  {
            props?.element?.onChanged(newValue);
          }
        }}
        className={ styles.autoComplete_input}
        // className={`${styles.form_input} ${styles.inputSelect}`}
        // inputValue={inputValue}
        // onInputChange={(event, newInputValue) => {
        //   setInputValue(newInputValue);
        // }}
        id="controllable-states-demo"
        options={props?.element?.values}
        getOptionLabel={(option: any) => option.name ? option.name : ''}
        sx={{ width: 300 }}
        // onChange={(event: SyntheticEvent<Element, Event>) => {
        //   // props?.element?.onChange(props?.element);
        // }}
        renderInput={(params) => (
          <TextField
            {...params}
          />
        )}
      /> */}
      {appState.fetchingFormItems && (
        <Box>
          <Skeleton className={`${styles.form_input} ${styles.form_select_skeleton}`} />
        </Box>
      )}
      {/* <Select
        options={props?.element?.values}
        getOptionLabel={(option: any) => option[`${props?.element?.name}`]}
        getOptionValue={(option: any) => option[`${props?.element?.id}`]}
        // onChange={(selectedOption: any) => props?.element?.onChanged(selectedOption)}
      /> */}
      {!appState.fetchingFormItems && (
        <Select
          options={props?.element?.values}
          value={props?.element.value}
          getOptionLabel={(option: any) => option[`${props?.element?.name}`]}
          getOptionValue={(option: any) => option[`${props?.element?.id}`]}
          onChange={(selectedOption: any) => props?.element?.onChanged(selectedOption)}
        />
      )}
    </>
  );
};

export default FormSelect2;
