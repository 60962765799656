import * as React from 'react';
import { Box, Button, CircularProgress, Container } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import styles from '../../components/index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import FormInputs from '../../components/UI/Inputs/FormInputs';
import FormHeader from '../../components/UI/Forms/FormHeader';
import Storage from '../../utils/Storage';
import { ExpenseCategory } from '../../interfaces/expenseCategory';
import { User } from '../../interfaces/user';
import { CompanyExpense } from '../../interfaces/companyExpense';
import { addAction, updateAction } from '../../store/app/globalActions';
import { addCompanyExpenseService } from '../../store/companyexpenses/companyExpenseService';
import { appActions } from '../../store/appState';
import { getExpenseCategoriesAction, updateCompanyExpense } from '../../store/companyexpenses/companyExpenseActions';

interface newPurchaseProps {
  open: boolean;
  close: Function;
  companyExpense?: CompanyExpense;
}

export default function NewCompanyExpense(props: newPurchaseProps) {
  const [formState, setFormState] = useState({
    date: new Date().toISOString().substring(0, 10) as any,
    amount: null as number | any,
    description: '' as string | any,
    category: null as ExpenseCategory | any,
    categoryText: null as string | any,
  });
  const dispatch = useDispatch();
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const user: User = Storage.getUser();
  const isSubmitting = useSelector((state: any) => state.app.isSubmitting);

  const onCategoryChanged = (category: any, found: boolean) => {
    if (found) {
      console.log("d ", category);
      setFormState({
        ...formState,
        category: category,
        categoryText: '',
      });
    } else {
      setFormState({
        ...formState,
        category: null,
        categoryText: category,
      });
    }
  };

  const onSubmit = () => {
    let data: any = {};
    data.date = formState.date;
    data.amount = +formState.amount;
    data.description = formState.description;
    data.category = formState.category._id;
    data.company = user.company._id;
    if (appState.openForm.action === 'add') {
      dispatch(
        addAction(addCompanyExpenseService, data, token, () => dispatch(appActions.setFetchedCompanyExpenses(false)))
      );
    } else {
      if (props?.companyExpense?._id)
        dispatch(
          updateAction(updateCompanyExpense, props.companyExpense._id, data, token, () =>
            dispatch(appActions.setFetchedCompanyExpenses(false))
          )
        );
    }
  };

  const newSaleElements = [
    {
      label: 'Date',
      identifier: 'date',
      inputComponent: 'date2',
      value: formState.date,
      onChange: (date: string) => setFormState({ ...formState, date }),
      required: true,
    },
    {
      label: 'Amount',
      identifier: 'amount',
      inputComponent: 'input2',
      type: 'number',
      placeholder: 'Enter Amount',
      required: true,
      value: formState.amount,
      onChange: (amount: any) => setFormState({ ...formState, amount }),
    },
    {
      label: 'Description',
      identifier: 'description',
      inputComponent: 'input2',
      placeholder: 'Enter Description',
      required: true,
      value: formState.description,
      onChange: (description: string) => setFormState({ ...formState, description }),
    },
    {
      label: 'Category',
      identifier: 'category',
      inputComponent: 'select3',
      values: appState.expenseCategories,
      onChange: (category: any, found: boolean) => onCategoryChanged(category, found),
      value: formState?.category?.name || formState.categoryText,
      name: 'name',
      id: '_id',
    },
  ];

  useEffect(() => {
    dispatch(getExpenseCategoriesAction(`?company=${user.company._id}`, token));
    if (appState.openForm.action === 'update') {
      setFormState({
        ...formState,
        amount: props?.companyExpense?.amount,
        category: props?.companyExpense?.category,
        description: props?.companyExpense?.description,
        date: props?.companyExpense?.date?.substring(0,10)
      })
    }
  }, []);

  return (
    <Container classes={{ root: styles.newPurchaseContainer }}>
      <Dialog
        classes={{ paper: styles.genericFormVertical }}
        open={appState.openForm.open}
        onClose={() => {
          props.close();
        }}
      >
        <FormHeader
          title={appState.openForm.action === 'add' ? 'Add COMPANY EXPENSE' : 'Update COMPANY EXPENSE'}
          close={() => {
            props.close();
          }}
        />
        <DialogContent>
          <Box className={styles.genericForm_column}>
            {newSaleElements.map((element: any, index: number) => {
              return <FormInputs key={index} element={element} style="vertical" />;
            })}
          </Box>
          <Box className={styles.center}>
            {isSubmitting && (
              <Button className={styles.confirmButton} variant="contained">
                <CircularProgress size={30} color="inherit" className={styles.circularProgress} />
              </Button>
            )}
            {!isSubmitting && (
              <Button onClick={() => onSubmit()} className={styles.confirmButton} variant="contained">
                {appState.openForm.action === 'update' ? "Update" : "Add"}
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
