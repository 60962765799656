import HttpRequest from "../../utils/HttpRequest";
import { SERVER_URL } from "../../utils/constants";

export const getItemsByStockId = async (id: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/construction/stockitems/stock/${id}`, token);
};

export const addStockService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/construction/projectstock`, data, token);
};
