import { Company } from "./company";
import { User } from "./user";

export interface clientColumn {
  id: 'name' | 'email' | 'contact' | 'tinNumber' | 'createdAt' | 'action'
  label: String,
  minWidth?: number,
  align?: 'center'
}

export const clientColumns: clientColumn[] = [
  { id: 'name', label: 'Name', align: 'center' },
  { id: 'email', label: 'E-mail', align: 'center' },
  { id: 'contact', label: 'Phone Number', align: 'center' },
  { id: 'tinNumber', label: 'Tin Number', align: 'center' },
  { id: 'createdAt', label: 'Created At', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
]

export interface clientData {
  id: string,
  name: string,
  tinNumber: number,
  contact: number,
  email: string,
  createdAt: string,
  action: any
}

export interface Client {
  _id: string;
  name: string;
  tinNumber: Number;
  contact: Number;
  email: String;
  representative: String;
  location: String;
  isActive: boolean;
  company?: Company;
  createdBy?: User;
  updatedAt?: string;
  createdAt?: string;
}
