import { SERVER_URL } from '../../utils/constants';
import HttpRequest from '../../utils/HttpRequest';

export const getConsExpenseService = async (query: any, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/construction/expenses${query}`, token);
};

export const searchConsExpenseService = async (query: any, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/construction/expenses/search${query}`, token);
};

export const addConsExpenseService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/construction/expenses`, data, token);
};

export const addManyConsExpenseService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/construction/expenses/many`, data, token);
};

export const updateConsExpenseService = async (expenseId: string, data: any, token: string) => {
  return await HttpRequest.update(`${SERVER_URL}/construction/expenses/${expenseId}`, data, token);
};

export const deleteExpense = async (id: String, token: string) => {
    return await HttpRequest.delete(`${SERVER_URL}/construction/expenses/${id}`, token);
  };