import { Dialog, DialogContent } from '@mui/material';
import styles from '../../components/index.module.scss';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DownloadIcon from '@mui/icons-material/Download';
import './style.css';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/appState';

const ImageView = (props: any) => {
  const [image, setImage] = useState(props.image);
  const appState: AppState = useSelector((state: any) => state.construction);
  const shuffleImage = (forward: boolean) => {
    let index = appState?.images?.findIndex((img: any) => image?.url === img.url);
    if (forward) {
      if (index + 1 === appState?.images?.length) {
        setImage(appState?.images[0]);
      } else {
        setImage(appState?.images[index + 1]);
      }
    } else {
      if (index === 0) {
        setImage(appState?.images[appState?.images?.length - 1]);
      } else {
        setImage(appState?.images[index - 1]);
      }
    }
  };
  return (
    <Dialog className="image_view" classes={{ paper: styles.genericForm }} open={props.open} onClose={props.close}>
      <DialogContent>
        <div>
          <div>
            <img style={{ height: '80vh', width: '80vw' }} src={image.url} alt={image.cloudinary_id} />
          </div>
          <div className="image_navigations">
            <ArrowBackIosIcon
              style={{ marginLeft: '0.5rem', fontSize: '3rem', cursor: 'pointer' }}
              onClick={() => shuffleImage(false)}
            />
            <ArrowForwardIosIcon style={{ fontSize: '3rem', cursor: 'pointer' }} onClick={() => shuffleImage(true)} />
          </div>
          <a href={image.url} download={image.cloudinary_id} target="_blank" rel="noreferrer">
            <DownloadIcon className="absolute top-4 right-4 cursor-pointer" style={{ fontSize: '2rem' }} />
          </a>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ImageView;
