import { useDispatch, useSelector } from "react-redux";
import { useRef, useState, useEffect } from "react";
import { Button, Typography, Box, CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import SearchIcon from "@mui/icons-material/Search";
import InputComponent from "../../components/UI/Inputs/InputComponent";
import SelectComponent from "../../components/UI/Inputs/SelectComponent";
import styles from "../../components/index.module.scss";
import Storage from "../../utils/Storage";
import { getClients } from "../../store/clients/clientActions";

export default function Filter(props: any) {
  const [init, setInit] = useState(true);
  const [fetching, setFetching] = useState(false);
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [partner, setPartner] = useState("");
  const amountRef = useRef<HTMLInputElement>(null);
  const clients = useSelector((state: any) => state.app.clients);
  const token = Storage.getToken();
  const company = Storage.getUser().company._id;

  var statusValues = [
    {
      name: "Open",
      _id: "open",
    },
    {
      name: "Closed",
      _id: "closed",
    },
  ];

  const handleStatus = (event: any) => {
    setStatus(event.target.value as string);
  };

  const handlePartnerChange = (event: any) => {
    setPartner(event.target.value as string);
  };

  useEffect(() => {
    if (init) {
      dispatch(getClients(`?company=${company}`, token));
    }
    return () => {
      setInit(false);
    };
  }, [company, dispatch, init, token]);

  const filter = () => {
    let chipData = [];
    let key = 0;
    if (partner) {
      var selectedClient = clients.find((client: any) => client._id === partner);
      console.log("selected ", selectedClient);
      chipData.push({
        key,
        filter: partner,
        id: "client",
        label: "Client",
        value: selectedClient?.name,
      });
      key++;
    }
    if (status) {
      chipData.push({ key, filter: status, id: "status", label: "Status" });
      key++;
    }
    if (amountRef.current?.value) {
      chipData.push({
        key,
        filter: amountRef.current?.value,
        id: "contractAmount",
        label: "Amount",
      });
      key++;
    }

    if (chipData.length > 0) {
      props.filterHandler(chipData);
      props.setOpen(false);
    } else {
      props.setOpen(false);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={`${styles.formHeader} ${styles.center}`}>
          <Typography className={styles.formHeaderText}>
            FILTER / SEARCH
          </Typography>
        </DialogTitle>
        <DialogContent className={styles.formContent}>
          {fetching && (
            <Box className={styles.loadingContainer}>
              <CircularProgress />
            </Box>
          )}
          {!fetching && (
            <Box>
              {clients.length > 0 && (
                <SelectComponent
                  label="Client"
                  value={partner}
                  values={clients}
                  setValue={(value: string) => setPartner(value)}
                  handleChange={handlePartnerChange}
                />
              )}
              <SelectComponent
                label="Status"
                value={status}
                _id="_id"
                name={"name"}
                values={statusValues}
                handleChange={handleStatus}
              />
              <InputComponent
                label="Purchase Order Amount"
                reference={amountRef}
                icon={<SearchIcon />}
                placeholder="Filter by PO Amount"
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Box className={styles.filterButtonsContainer}>
            <Button
              onClick={() => filter()}
              variant="contained"
              className={styles.filterButton}
            >
              Filter
            </Button>
            <Button
              onClick={() => props.setOpen(false)}
              variant="contained"
              className={styles.cancelButton}
            >
              Clear
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
