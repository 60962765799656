import axios from "axios";
import { SERVER_URL } from "../../utils/constants";

export const ConstructionService = {
  closeProject: async (id: string) => {
    try {
      const res = await axios({
        method: "PATCH",
        url: `${SERVER_URL}/project/Projects/${id}`,
        data: {
          status: "closed",
        },
      });
    } catch (err: any) {
      console.log(err.response.data.message);
    }
  },
};

export const getStocksByProjectId = async (id: any) => {
  try {
    return await axios({
      method: "GET",
      url: `${SERVER_URL}/construction/projectstock/project/${id}`,
    });
  } catch (err) {
    console.log(err);
  }
};
