import { Typography } from 'antd';
import styles from '../index.module.scss';
import logo from '../../../assets/images/logo_small.png';
import { LoadingButton } from '@mui/lab';
import { Form, Input } from 'antd';
import { primaryColor } from '../../../assets/colors/colors';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { requestFailed, setResponseAction } from '../../../utils/functions';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { appActions } from '../../../store/appState';
import { addCompanyService } from '../../../store/company/companyService';

const RegisterBusiness = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const registerBusinessHandler = async (values: any) => {
    if (!values.location) delete values.location;
    if (!values.contact) delete values.contact;
    if (!values.representative) delete values.representative;
    if (!values.tinNumber) {
      delete values.tinNumber;
    } else {
      values.tinNumber = +values.tinNumber;
    }
    if (!values.email) delete values.email;

    console.log('valuessssss ', values);
    setLoading(true);
    const res = await addCompanyService(values);
    setLoading(false);
    if (requestFailed(res)) {
      dispatch(setResponseAction(res, 'fail', res.message));
    } else {
      dispatch(appActions.setCompany(res.data));
      dispatch(setResponseAction(res, 'success', res.message));
      navigate('/signup');
    }
  };

  return (
    <div className={styles.authBackground}>
      <div className={styles.authCard}>
        <div className={styles.authEllipse}></div>
        <div className={styles.authCardContent}>
          <div className="w-full border-r border-5 border-gray-600 text-center flex items-center justify-center flex-col">
            <div className="text-primaryColor font-poppins font-bold  text-xl text-center">REGISTER NEW COMPANY</div>

            <div className="text-black font-semibold text-sm">Please enter your details</div>

            <Typography className="text-xl text-primaryColor font-bold mt-3 mb-2 border-b border-primaryColor">
              REGISTER
            </Typography>
            <div className="login_form w-8/12">
              <Form form={form} onFinish={registerBusinessHandler}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the company name!',
                    },
                  ]}
                >
                  <Input placeholder="Company Name*" />
                </Form.Item>
                <Form.Item name="representative">
                  <Input placeholder="Representative" />
                </Form.Item>
                <Form.Item name="location">
                  <Input placeholder="Address" />
                </Form.Item>
                <Form.Item
                  name="tinNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the company Tin Number!',
                    },
                  ]}
                >
                  <Input placeholder="Tin Number*" />
                </Form.Item>
                <Form.Item name="email">
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="contact"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the company Contact Number!',
                    },
                  ]}
                >
                  <Input placeholder="Primary Contact" />
                </Form.Item>
                <LoadingButton
                  variant="contained"
                  className="w-full"
                  type="submit"
                  loading={loading}
                  loadingPosition="center"
                  loadingIndicator={<CircularProgress style={{ color: 'white' }} size={16} />}
                  style={{
                    width: '100%',
                    marginTop: '0.8rem',
                    backgroundColor: primaryColor,
                  }}
                >
                  Continue
                </LoadingButton>
              </Form>
            </div>
          </div>

          <div className="w-full flex items-center justify-center">
            <img style={{ height: '10rem' }} alt="traceup" src={logo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterBusiness;
