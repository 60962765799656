import { List, ListItem, ListItemText } from '@mui/material';
import { Link, Outlet, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import { User } from '../../interfaces/user';
import Storage from '../../utils/Storage';
import HomeNavMenu from '../../components/NavMenu/HomeNavMenu';
import Layout from '../../components/UI/others/Layout';

const DashboardMain = (props: any) => {
  const user: User = Storage.getUser();
  const router = useLocation();
  const pathName = router.pathname;

  return (
    <Layout title={`${user?.company?.name} PROJECTS`} navMenu={<HomeNavMenu />}>
      <List className="page_nav">
        <ListItem
          className="flex-1"
          component={Link}
          to="/projects"
          classes={{ root: styles.root, selected: styles.listItemSelected }}
          selected={`/projects` === pathName}
        >
          <ListItemText primary="Projects" className={styles.listItemText} />
        </ListItem>
        <ListItem
          className="flex-1"
          component={Link}
          to="/overview"
          classes={{ root: styles.root, selected: styles.listItemSelected }}
          selected={`/overview` === pathName}
        >
          <ListItemText primary="Overview" className={styles.listItemText} />
        </ListItem>
      </List>
      <Outlet />
    </Layout>
  );
};

export default DashboardMain;
