import { SERVER_URL } from "../../utils/constants";
import { exportExcel, setResponseAction } from "../../utils/functions";
import HttpRequest from "../../utils/HttpRequest";
import { appActions } from "../appState";
import {
  addConsExpenseService,
  getConsExpenseService,
  searchConsExpenseService,
} from "./expensesService";

export const createExpense = async (data: any, token: string) => {
  try {
    await addConsExpenseService(data, token);
  } catch (err: any) {
    console.log(err.response.data.message);
  }
};
export const updateExpense = async (id: String, data: any, token: string) => {
  return await HttpRequest.update(
    `${SERVER_URL}/construction/expenses/${id}`,
    data,
    token
  );
};

export const getExpenses = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setFetching(true));
      const res = await getConsExpenseService(query, token);
      if (
        res.response?.data.status === "fail" ||
        res.response?.data.status === "error"
      ) {
        dispatch(setResponseAction(res));
      } else {
        dispatch(appActions.setExpenses({ expenses: res.data }));
        dispatch(appActions.setCount({ count: res.count }));
      }
      dispatch(appActions.setUpdateNeeded(false));
      dispatch(appActions.setFetching(false));
    } catch (err) {
      console.log("error: ", err);
    }
  };
};

export const searchExpensesAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setFetching(true));
      const res = await searchConsExpenseService(query, token);
      if (
        res.response?.data.status === "fail" ||
        res.response?.data.status === "error"
      ) {
        dispatch(setResponseAction(res));
      } else {
        dispatch(appActions.setExpenses({ expenses: res.data }));
        dispatch(appActions.setCount({ count: res.count }));
      }
      dispatch(appActions.setUpdateNeeded(false));
      dispatch(appActions.setFetching(false));
    } catch (err) {
      console.log("error: ", err);
    }
  };
};

export const exportExpensesAction = (
  query: string,
  token: string,
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getConsExpenseService(query, token);
      if (
        res.response?.data.status === "fail" ||
        res.response?.data.status === "error"
      ) {
        dispatch(setResponseAction(res));
      } else {
        let data = res.data.map((currentItem: any) => {
          return {
            Date: currentItem.date.substring(0, 10),
            Description: currentItem.description,
            Amount: currentItem.amount,
          };
        });
        exportExcel(data, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log("error: ", err);
    }
  };
};
