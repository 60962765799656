import { useEffect, useState } from "react";
import {
  Box,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import styles from "./../index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Storage from "../../utils/Storage";
import LoadingContainer from "../../components/UI/others/LoadingContainer";
import NoData from "../../components/UI/others/NoData";
import ConfirmModal from "../../components/UI/others/ConfirmModal";
import { AppState, appActions } from "../../store/appState";
import NewPayrollDocument from "./NewPayrollDocument";
import { numberWithCommas } from "../../utils/functions";
import { SelectedElement } from "../../interfaces";
import { getPayrolls } from "../../store/documents/documentActions";
import { deleteDocumentService } from "../../store/documents/documentsService";
import { deleteAction } from "../../store/app/globalActions";

const PayrollDocuments = () => {
  const appState: AppState = useSelector((state: any) => state.app);
  const [document, setDocument] = useState({} as SelectedElement);
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const project = Storage.getProject()._id;

  useEffect(() => {
    if (!appState.fetchedPayroll) {
      dispatch(
        getPayrolls(`?project=${project}&type=payroll`, token, () =>
          dispatch(appActions.setFetchedPayroll(true))
        )
      );
    }
  }, [
    appState.updateNeeded,
    dispatch,
    project,
    token,
    appState.fetchedPayroll,
  ]);

  const deleteDocumentHandler = () => {
    dispatch(
      deleteAction(deleteDocumentService, document.id, token, "Document", () =>
        dispatch(appActions.setFetchedPayroll(false))
      )
    );
  };

  return (
    <>
      <>
        <Box className={styles.aRight}>
          <Button
            style={{ marginBottom: "1rem" }}
            variant="contained"
            onClick={() =>
              dispatch(appActions.setOpenForm({ open: true, action: "add" }))
            }
          >
            Upload New Payroll
          </Button>
        </Box>
        <Box className={styles.galleryContainer}>
          {!appState.isFetching && (
            <TableContainer component={Paper}>
              <Table
                className={styles.table}
                classes={{ root: styles.customTable }}
                size="small"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">FROM</TableCell>
                    <TableCell align="center">TO</TableCell>
                    <TableCell align="center">AMOUNT</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                {appState?.payrolls?.length > 0 && (
                  <TableBody>
                    {appState?.payrolls.map(
                      (row: any, index: number) => (
                        <TableRow key={row.date + index}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {row?.payroll?.from.substring(0, 10)}
                          </TableCell>
                          <TableCell align="center">
                            {row?.payroll?.to.substring(0, 10)}
                          </TableCell>
                          <TableCell align="center">
                            {numberWithCommas(row?.payroll?.amount)}
                          </TableCell>
                          <TableCell align="center">
                            <Box className={styles.poActions}>
                              <a
                                className={styles.link}
                                href={row.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <VisibilityIcon
                                  style={{ marginRight: "10px" }}
                                />
                              </a>
                              <a
                                className={styles.link}
                                href={row.url}
                                target="_blank"
                                download
                              >
                                <CloudDownloadIcon />
                              </a>
                              <DeleteIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setDocument({
                                    name: row?.name,
                                    id: row?._id,
                                  });
                                  dispatch(appActions.setIsDeleting(true));
                                }}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          )}
          {!appState.isFetching &&
            appState?.payrolls?.length === 0 && <NoData />}
          {appState.isFetching && <LoadingContainer />}
        </Box>
      </>

      {appState.openForm.open && (
        <NewPayrollDocument
          open={appState.openForm.open}
          close={() => dispatch(appActions.closeForm())}
        />
      )}
      {appState.isDeleting && (
        <ConfirmModal
          onConfirm={deleteDocumentHandler}
          content={`Please confirm to delete Payroll document`}
        />
      )}
    </>
  );
};

export default PayrollDocuments;
