import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import GenericForm from '../../components/UI/Forms/GenericForm';
import { useForm } from 'react-hook-form';
import { getNumberWithCommas, numberWithCommas } from '../../utils/functions';
import Storage from '../../utils/Storage';
import InputForm from '../../components/InputForm';
import { Form } from 'antd';
import dayjs from 'dayjs';
import { Project } from '../../interfaces/project';
import { Client } from '../../interfaces/clients';
import { getClients } from '../../store/clients/clientActions';
import { User } from '../../interfaces/user';
import { addAction, updateAction } from '../../store/app/globalActions';
import { addConsProjectService, updateConsProjectService } from '../../store/projects/projectsService';
import { AppState, appActions } from '../../store/appState';

interface NewProjectProps {
  open: boolean;
  close: Function;
  project?: Project;
}

export default function NewProject(props: NewProjectProps) {
  const appState: AppState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [client, setClient] = useState({} as Client);

  const clients: Client[] = useSelector((state: any) => state.app.clients);
  const user: User = Storage.getUser();

  useEffect(() => {
    if (!appState.fetchedClients) {
      dispatch(getClients(`?company=${user.company._id}`, token));
    }

    if (appState.openForm.action === 'update') {
      form.setFieldsValue({
        name: props.project?.name,
        contractAmount: props.project?.contractAmount,
        startDate: dayjs(props?.project?.startDate),
        client: props.project?.client?._id,
      });
    }
  }, []);

  const submit = (values: any) => {
    console.log(values);
    values.startDate = values.startDate?.toDate() ?? new Date();
    values.company = user.company._id;
    console.log(values);

    // data.contractAmount = getNumberWithCommas(data.contractAmount);
    // data.client = client._id;
    // data.company = user.company._id;

    if (appState.openForm.action === 'update' && props?.project !== undefined) {
      dispatch(
        updateAction(updateConsProjectService, props?.project._id, values, token, () =>
          dispatch(appActions.setFetchedProjects(false))
        )
      );
    } else {
      dispatch(addAction(addConsProjectService, values, token, () => dispatch(appActions.setFetchedProjects(false))));
    }
  };

  const clientOptions = clients.map((client: Client) => {
    return {
      value: client._id,
      label: client.name,
    };
  });
  const formElements = [
    {
      name: 'name',
      label: 'Project Name',
      type: 'text',
      rules: [{ required: true, message: 'Please input the project name' }],
    },
    {
      name: 'contractAmount',
      label: 'Purchase Order',
      type: 'number',
      rules: [{ required: true, message: 'Please enter the PO Amount' }],
    },
    {
      name: 'startDate',
      label: 'Start Date',
      type: 'date',
    },
    {
      name: 'client',
      label: 'client',
      type: 'select',
      options: clientOptions,
      rules: [{ required: true, message: 'Please choose the client' }],
    },
  ];

  return (
    <InputForm
      open={props.open}
      close={() => props.close()}
      title="New Project / Site"
      styles="vertical"
      formElements={formElements}
      onSubmit={submit}
      form={form}
    />
  );
}
