import { Box, Dialog, DialogContent } from '@mui/material';
import styles from './index.module.scss';
import dayjs from 'dayjs';
import FormHeader from './UI/Forms/FormHeader';
import { Form, Input, Select, InputNumber, DatePicker } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from '../i18n';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';

interface InputFormProps {
  open: boolean;
  close: Function;
  title: string;
  formElements: any;
  onSubmit: (values: any) => void;
  form: any;
  styles?: string;
  confirmText?: string;
}

export type LayoutType = Parameters<typeof Form>[0]['layout'];


export const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const InputForm = (props: InputFormProps) => {
  const currentDate = dayjs();
  const isSubmitting = useSelector((state: any) => state.app.isSubmitting);
  const [formLayout, setFormLayout] = useState<LayoutType>('vertical');
  const { t } = useTranslation();

  return (
    <Dialog
      classes={{
        paper: props?.styles === 'vertical' ? styles.genericFormVertical : styles.genericForm,
      }}
      open={props.open}
      onClose={() => props.close()}
    >
      <FormHeader title={props.title} close={props.close} />
      <DialogContent>
        <Form
          {...layout}
          layout={formLayout}
          form={props.form}
          name="control-hooks"
          onFinish={props.onSubmit}
          className="my_form"
        >
          <Box style={{ margin: '0 auto' }}>
            {props.formElements.map((element: any) => (
              <Box
                key={element.name}
                className={props?.styles === 'horizontal' ? styles.forms_input_horizontal : styles.forms_input_vertical}
              >
                <Form.Item name={element.name} label={element.label} rules={element.rules ? element.rules : []}>
                  {element.type === 'number' && <InputNumber />}
                  {element.type === 'text' && <Input />}
                  {element.type === 'date' && <DatePicker defaultValue={currentDate} />}
                  {element.type === 'select' && (
                    <Select
                      showSearch
                      placeholder="Select a person"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label as string).toLowerCase().includes(input.toLowerCase())
                      }
                      options={element.options}
                    />
                  )}
                </Form.Item>
              </Box>
            ))}
          </Box>
          <Box className={styles.form_action}>
            <LoadingButton
              variant="contained"
              type="submit"
              className={styles.confirmButton}
              loading={isSubmitting}
              size="large"
            >
              {t('save')}
            </LoadingButton>
          </Box>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default InputForm;
