import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopActions from "../../components/UI/others/TopActions";
import ExpensesTable from "./ExpensesTable";
import { Grid } from "@mui/material";
import Banner from "../../components/Construction/Banner";
import store from "store";
import * as XLSX from "xlsx";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { AppState, appActions } from "../../store/appState";
import ConstructionNavMenu from "../../components/NavMenu/ConstructionNavMenu";
import Layout from "../../components/UI/others/Layout";
import Storage from "../../utils/Storage";
import {
  exportExpensesAction,
  getExpenses,
  searchExpensesAction,
} from "../../store/expenses/expensesActions";
import ProjectDetails from "../../ProjectDetails";
import { Button } from "antd";
import { exportExcel } from "../../utils/functions";
import UploadExcelExpenses from "./upload_excel_expenses";
import { addAction } from "../../store/app/globalActions";
import { addManyConsExpenseService } from "../../store/expenses/expensesService";

const ProjectExpenses = () => {
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [isInit, setIsInit] = useState(true);
  const [count, setCount] = useState(25);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const project = store.get("project");
  const token = Storage.getToken();
  const appState: AppState = useSelector((state: any) => state.app);
  const dispatch = useDispatch();
  const [excelData, setExcelData] = useState<any[][]>([]);
  const [uploadExcel, setUploadExcel] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");

  useEffect(() => {
    if (!appState.isFetching) {
      setCount(appState.count);
    }
  }, [appState.isFetching, appState.count]);

  useEffect(() => {
    if (project._id)
      dispatch(
        getExpenses(
          `?project=${project._id}&page=${page}&limit=${rowsPerPage}&sort=-date`,
          store.get("x-auth-token")
        )
      );
  }, [project._id, dispatch]);

  useEffect(() => {
    if (appState.updateNeeded)
      dispatch(
        getExpenses(
          `?project=${project._id}&page=${page}&limit=${rowsPerPage}&sort=-date`,
          store.get("x-auth-token")
        )
      );
  }, [appState.updateNeeded, project._id, dispatch]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(
          searchExpensesAction(
            `?text=${searchText}&project=${project._id}`,
            token
          )
        );
        setIsInit(false);
      } else {
        if (!appState.isFetching && !appState.updateNeeded && !isInit) {
          dispatch(appActions.setUpdateNeeded(true));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, searchText, token]);

  const exportExpensesHandler = () => {
    dispatch(
      exportExpensesAction(
        `?project=${project._id}&sort=-date&limit=20000`,
        token,
        `${project.name} Expenses`
      )
    );
  };

  const serialToDate = (serial: number) => {
    const baseDate = new Date("1899-12-30"); // Excel base date
    const millisecondsInADay = 86400000; // 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
    const offsetMilliseconds = serial * millisecondsInADay;
    return new Date(baseDate.getTime() + offsetMilliseconds)
      .toISOString()
      .substring(0, 10);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        console.log("sheet name ", workbook);
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        let jsonData: any = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        jsonData = jsonData.slice(1);
        const excelDataWithDates = jsonData.map((row: any[]) => {
          return [serialToDate(row[0]), row[1], +row[2]];
        });

        setExcelData(excelDataWithDates);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const uploadExpensesHandler = () => {
    const data: any = excelData?.map((data) => {
      return {
        date: data[0],
        description: data[1],
        amount: data[2],
        paid: data[2],
        project: store.get("project")._id,
      };
    });

    dispatch(
      addAction(addManyConsExpenseService, data, token, () => {
        setUploadExcel(false);
        dispatch(appActions.setUpdateNeeded(true));
      })
    );
  };

  return (
    <Layout
      title={`${project.name} • Expenses`}
      select={1}
      navMenu={<ConstructionNavMenu select={1} />}
    >
      <div className="flex items-center">
        <div className="w-full">
          <TopActions
            setNew={() =>
              dispatch(appActions.setOpenForm({ open: true, action: "add" }))
            }
            entity="Expense"
            setSearchText={setSearchText}
            export={exportExpensesHandler}
            addNew={true}
          />
        </div>
        <Button
          icon={<UploadFileIcon />}
          className="ms-4 bg-primaryColor text-white uppercase pb-3"
          onClick={() => setUploadExcel(true)}
        >
          Upload Expenses
        </Button>
      </div>
      <div className="text-right">
        <Button
          className="bg-primaryColor text-white uppercase"
          onClick={() => {
            let data = [
              {
                date: "mm/dd/yyyy",
                description: "depanse",
                amount: 0,
              },
            ];
            exportExcel(data, "expenses_template");
          }}
        >
          Excel Template
        </Button>
      </div>
      <Banner id={project.id} />
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <ExpensesTable
            count={count}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Grid>
        <Grid item xs={3}>
          <ProjectDetails />
        </Grid>
      </Grid>
      {uploadExcel && (
        <UploadExcelExpenses
          open={uploadExcel}
          uploading={appState.isSubmitting}
          fileName={fileName}
          handleFileUpload={handleFileUpload}
          uploadHandler={uploadExpensesHandler}
          close={() => setUploadExcel(false)}
        />
      )}
    </Layout>
  );
};

export default ProjectExpenses;
