import { SERVER_URL } from '../../utils/constants';
import HttpRequest from '../../utils/HttpRequest';

export const addConsProjectService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/construction/projects`, data, token);
};

export const getConsProjectsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/construction/projects${query}`, token);
};

export const getConsProjectsStatsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/construction/projects/stats${query}`, token);
};

export const searchConsProjectsService = async (query: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/construction/projects/search/projects${query}`, token);
};

export const getConsProjectService = async (project: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/construction/projects/${project}`, token);
};

export const updateConsProjectService = async (projectId: string, data: any, token: string) => {
  return await HttpRequest.update(`${SERVER_URL}/construction/projects/${projectId}`, data, token);
};

export const closeProjectService = async (projectId: string, data: any, token: string) => {
  return await HttpRequest.update(`${SERVER_URL}/construction/projects/close/${projectId}`, data, token);
};

export const deleteConsProjectService = async (projectId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/construction/projects/${projectId}`, token);
};
