// export const SERVER_URL = "http://127.0.0.1:4000/api/v2";

export const SERVER_URL = "https://backend.traceup.rw/api/v2";

export interface PaymentMethod {
  name: "Momo" | "Cash" | "Cheque" | "Bank";
  id: "momo" | "cash" | "cheque" | "bank";
}

export const paymentMethods: PaymentMethod[] = [
  {
    name: "Cash",
    id: "cash",
  },
  {
    name: "Momo",
    id: "momo",
  },
  {
    name: "Cheque",
    id: "cheque",
  },
  {
    name: "Bank",
    id: "bank",
  },
];
