import { Routes, Route } from "react-router-dom";
import ProjectExpenses from "../pages/expenses/ProjectExpenses";
import ConstructionStock from "../pages/stock/Dashboard";
import Payroll from "../pages/payroll/payroll";
import ConstructionSuppliers from "../pages/suppliers/ConstructionSuppliers";
import ConstructionDocuments from "../pages/documents/Document";
import ConstructionImages from "../pages/images/Images";
import DashboardMain from "../pages/Dashboard/DashboardMain";
import Home from "../pages/Dashboard/Dashboard";
import ProjectsOverview from "../pages/Dashboard/Overview";
import Reports from "../pages/reports/Reports";
import CompanyExpenses from "../pages/company expenses/CompanyExpenses";
import Clients from "../pages/Clients/Clients";
import Login from "../pages/Auth/login";
import Signup from "../pages/Auth/signup/Signup";
import Register from "../pages/Auth/Register/Register";

const MainRouter = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/register" element={<Register />} />
      <Route path="/" element={<DashboardMain />}>
        <Route path="/projects" element={<Home />} />
        <Route path="/overview" element={<ProjectsOverview />} />
      </Route>
      <Route path="/expenses" element={<CompanyExpenses />} />
      <Route path="/stock" element={<ConstructionStock />} />
      <Route path="/payroll" element={<Payroll />} />
      <Route path="/documents" element={<ConstructionDocuments />} />
      <Route path="/images" element={<ConstructionImages />} />
      <Route path="/suppliers" element={<ConstructionSuppliers />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/project/expenses" element={<ProjectExpenses />} />
      <Route path="/clients" element={<Clients />} />
    </Routes>
  );
};

export default MainRouter;
