import { Box, TextField, Typography } from "@mui/material";
import styles from "../../../pages/index.module.scss";

const DatePickerComponent = (props: any) => {
  return (
    <Box className={styles.inputComponent}>
      <Typography className={styles.label}>{props.label}</Typography>
      {/* <Input
                placeholder={props.placeholder}
                disableUnderline={true}
                className={styles.input}
            /> */}
      <TextField
        id="date"
        type="date"
        inputRef={props?.reference}
        defaultValue={props?.defaultValue}
        className={styles.dateInput}
        InputProps={{ disableUnderline: true }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
};

export default DatePickerComponent;
