import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import appReducer from "./appState";

const store = configureStore({
  reducer: {
    auth: authReducer,
    app: appReducer,
  },
});

export default store;
