import { Select, MenuItem, Box, Typography } from "@mui/material";
import styles from "../../../pages/index.module.scss";

export const onSelectChanged = (event: any, onChange: Function) => {
  onChange(event.target.value as string);
};

const SelectComponent = (props: any) => {
  console.log("propss.name  ==", props.name, "==");
  console.log("value ", props.value);
  console.log("values ", props.values);
  return (
    <Box className={styles.inputComponent}>
      <Typography className={styles.label}>{props.label}</Typography>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        className={`${styles.input} ${styles.inputSelect}`}
        value={props.value}
        onChange={(event: any) => onSelectChanged(event, props.setValue)}
      >
        {props?.values?.map((option: any) => (
          <MenuItem key={option?._id} value={option?._id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default SelectComponent;
