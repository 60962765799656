import { Tabs, Tab } from '@mui/material';
import { useState } from 'react';
import Layout from '../../components/UI/others/Layout';
import ConstructionNavMenu from '../../components/NavMenu/ConstructionNavMenu';
import PayrollDocuments from './PayrollDocuments';
import SiteEmployees from './SiteEmployees';
import Storage from '../../utils/Storage';

const Payroll = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Layout title={`${Storage.getProject().name} • PAYROLLS`} navMenu={<ConstructionNavMenu select={3} />}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        scrollButtons={false}
        aria-label="scrollable prevent tabs example"
      >
        <Tab label="LISTE DE PAIE" />
        <Tab label="EMPLOYEES" />
      </Tabs>
      {activeTab === 0 && <PayrollDocuments />}
      {activeTab === 1 && <SiteEmployees />}
    </Layout>
  );
};

export default Payroll;
