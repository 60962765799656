import { ExpenseCategory } from "./expenseCategory";
import { User } from "./user";

export interface CompanyExpenseColumn {
    id: 'date' | 'description' | 'amount' | 'category' | 'action';
    label: string;
    minWidth?: number;
    align?: 'center';
  }
  
  export const companyExpensesColumns: CompanyExpenseColumn[] = [
      { id: 'date', label: 'Date', align: 'center' },
    { id: 'description', label: 'Description', align: 'center' },
    { id: 'amount', label: 'Amount', align: 'center' },
    { id: 'category', label: 'Category', align: 'center' },
    { id: 'action', label: 'Action', align: 'center' },
];

export interface CompanyExpenseRow {
    id: string;
    date: string;
    description: string;
    amount: string;
    category: string;
    action: any;
  }

export interface CompanyExpense {
  _id: string,
  amount: number;
  paid: number;
  date: string;
  description: string;
  category: ExpenseCategory;
  createdBy: User;
  createdAt: string;
  updatedAt: string;
}
