import React, { useEffect, useState } from "react";
import {
  TextField,
  Alert,
  Collapse,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import styles from "../index.module.scss";
import logo from "../../../assets/images/logo_small.png";
import google from "../../../assets/icons/google.png";
import { Link, useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";

import { useSelector, useDispatch } from "react-redux";
import { loginAction } from "../../../store/auth/authActions";
import { Form, Input, Typography } from "antd";
import { primaryColor } from "../../../assets/colors/colors";

const Login = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [form] = Form.useForm();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const { status, isFetching } = useSelector((state: any) => state?.auth);

  const handleLogin = () => {
    loginAction({
      username: form.getFieldValue("username"),
      password: form.getFieldValue("password"),
    })(dispatch);
  };
  useEffect(() => {
    if (status === 200) {
      navigate("/");
    } else if (status === 401) {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 4000);
    }
  }, [status, isFetching, navigate]);
  return (
    <div className={styles.authBackground}>
      <div className={styles.authCard}>
        <div className={styles.authEllipse}></div>
        <div className={styles.authCardContent}>
          <div className="w-full border-r border-5 border-gray-600 text-center flex items-center justify-center flex-col">
            <div className="text-primaryColor font-poppins font-bold  text-2xl text-center">
              Hi, Welcome back
            </div>

            <div className="text-black font-semibold">
              Welcome back please enter your details
            </div>

            <Typography className="text-xl text-primaryColor font-bold mt-3 mb-2 border-b border-primaryColor">
              LOG IN
            </Typography>
            <div className="login_form w-8/12">
              <Form form={form} onFinish={handleLogin}>
                <Typography className="w-full font-semibold text-left ms-2">
                  Username
                </Typography>
                <Form.Item
                  name="username"
                  rules={[
                    { required: true, message: "Please enter your e-mail!" },
                  ]}
                >
                  <Input placeholder="Enter your username" />
                </Form.Item>
                <Typography className="w-full font-semibold text-left ms-2">
                  Password
                </Typography>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="• • • • • • • • •" />
                </Form.Item>
                <div className="flex justify-between items-center text-sm w-full">
                  <div className="flex mt-1">
                    <input type="checkbox" className="me-1" />
                    <Typography className="text-sm">Remember Me</Typography>
                  </div>

                  <a className="" href="#">
                    <Typography className="text-blue-400 text-sm">
                      Forgot password?
                    </Typography>
                  </a>
                </div>
                <LoadingButton
                  variant="contained"
                  className="w-full"
                  type="submit"
                  loading={isFetching}
                  loadingPosition="center"
                  loadingIndicator={
                    <CircularProgress style={{ color: "white" }} size={16} />
                  }
                  style={{
                    width: "100%",
                    marginTop: "0.8rem",
                    backgroundColor: primaryColor,
                  }}
                >
                  Login
                </LoadingButton>
              </Form>
              <LoadingButton
                variant="contained"
                style={{
                  width: "100%",
                  marginTop: "2rem",
                  background: "white",
                  color: "black",
                  bottom: "1rem",
                  fontSize: "12px",
                  fontFamily: "Poppins",
                }}
              >
                <div className={styles.googlelog}>
                  <img alt="google" className="me-1" src={google} />
                </div>
                Sign in with Google
              </LoadingButton>
              <Typography className="text-sm">
                Don’t have a business Here?
              </Typography>
              <Link to="/register">
                <Typography className="text-sm text-blue-600 underline">
                  Create New Business
                </Typography>
              </Link>
            </div>
          </div>

          <div className="w-full flex items-center justify-center">
            <img style={{ height: "8rem" }} alt="traceup" src={logo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
