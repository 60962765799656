import ConstructionNavMenu from "../../components/NavMenu/ConstructionNavMenu";
import Layout from "../../components/UI/others/Layout";
import Storage from "../../utils/Storage";
import Files from "./Files";

const ConstructionDocuments = () => {
  return (
    <Layout
      title={`${Storage.getProject().name} • Documents`}
      navMenu={<ConstructionNavMenu select={4} />}
    >
      <Files />
    </Layout>
  );
};

export default ConstructionDocuments;
