import { Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Skeleton } from '@mui/material';
import { useEffect } from 'react';
import Storage from './utils/Storage';
import { LabelValuePair2, LabelValuePairCheck, LabelValuePairSkeleton } from './components/UI/others/LabelValuePair';
import { getDocumentsStatsAction } from './store/documents/documentActions';
import { AppState } from './store/appState';

const ProjectDetails = () => {
  const token = Storage.getToken();
  const appState: AppState = useSelector((state: any) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!appState.fetchedDocumentStats && appState?.project?._id) {
      dispatch(getDocumentsStatsAction(`?project=${appState?.project?._id}`, token));
    }
  }, [appState.fetchedDocumentStats, appState?.project?._id]);

  return (
    <>
      <Typography className="font-bold mb-2 text-xl">About Project</Typography>
      <div className="bg-white rounded-md p-4">
        <LabelValuePair2 label="Name" value={appState?.project?.name} />
        <LabelValuePair2 label="Client" value={appState?.project?.client?.name ?? 'N/A'} />
        <LabelValuePair2 label="Status" value={appState?.project?.status} />
        <LabelValuePair2
          label="Duration"
          value={moment.duration(moment().diff(moment(appState?.project?.startDate))).humanize()}
        />
        <LabelValuePair2 label="id" value={appState?.project?._id} />
      </div>
      <Typography className="font-bold mb-2 mt-6 text-xl">Project Documents</Typography>
      <div className="bg-white rounded-md p-4">
        {!appState.fetchingDocumentStats && (
          <>
            <LabelValuePairCheck label="Purchase Order" value={appState?.documentsStats?.po} />
            <LabelValuePairCheck label="Images" value={appState?.documentsStats?.images} />
            <LabelValuePairCheck label="Payrolls" value={appState?.documentsStats?.payroll} />
            <LabelValuePair2 label="Invoices" value={appState?.documentsStats?.invoice} />
            <LabelValuePair2 label="Letters" value={appState?.documentsStats?.letters} />
          </>
        )}
        {appState.fetchingDocumentStats && (
          <>
            <LabelValuePairSkeleton />
            <LabelValuePairSkeleton />
            <LabelValuePairSkeleton />
            <LabelValuePairSkeleton />
            <LabelValuePairSkeleton />
          </>
        )}
      </div>
    </>
  );
};

export default ProjectDetails;
