import { Typography } from "antd";
import styles from "../index.module.scss";
import google from "../../../assets/icons/google.png";
import logo from "../../../assets/images/logo_small.png";
import { LoadingButton } from "@mui/lab";
import { Form, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { primaryColor } from "../../../assets/colors/colors";
import { requestFailed, setResponseAction } from "../../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { authActions } from "../../../store/auth";
import { AppState } from "../../../store/appState";

const Signup = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const appState: AppState = useSelector((state: any) => state.app);

  const signUpHandler = async (values: any) => {
    if (!values.phone) delete values.phone;
    if (!values.email) delete values.email;
    values.role = "user";
    values.company = appState.company?._id;
    // setLoading(true);
    // // const res = await signupUserService(values);
    // setLoading(false);
    // if (requestFailed(res)) {
    //   dispatch(setResponseAction(res, "fail", res.message));
    // } else {
    //   dispatch(setResponseAction(res, "success", res.message));
    //   // dispatch(authActions.setUser(res.data));
    //   navigate("/login");
    // }
  };
  return (
    <div className={styles.authBackground}>
      <div className={styles.authCard}>
        <div className={styles.authEllipse}></div>
        <div className={styles.authCardContent}>
          <div className="w-full border-r border-5 border-gray-600 text-center flex items-center justify-center flex-col">

            <Typography className="text-xl text-primaryColor font-bold mt-3 mb-2 border-b border-primaryColor">
              SIGIN UP
            </Typography>

            <div className="text-black font-semibold text-sm mb-4">
              Please enter your personal details
            </div>
            <div className="login_form w-8/12">
              <Form form={form} onFinish={signUpHandler}>
                <Form.Item
                  name="names"
                  rules={[
                    { required: true, message: "Please enter your names!" },
                  ]}
                >
                  <Input placeholder="Full Names*" />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please enter your names!" },
                  ]}
                >
                  <Input placeholder="Email*" />
                </Form.Item>
                <Form.Item
                  name="phone"
                  rules={[
                    { required: true, message: "Please input your phone" },
                  ]}
                >
                  <Input placeholder="Phone" />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password*" />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Comfirm password*" />
                </Form.Item>
                {/* <div className="flex justify-between  text-sm w-full">
                <div className="flex mt-1">
                  <input
                    type="radio"
                    id="html"
                    name="fav_language"
                    value="HTML"
                    className="me-1"
                  />
                  <Typography className="text-sm">Male</Typography>
                  <input
                    type="radio"
                    id="html"
                    name="fav_language"
                    value="HTML"
                    className="me-1 ml-3"
                  />
                  <Typography className="text-sm ">Female</Typography>
                </div>
              </div> */}
                <LoadingButton
                  variant="contained"
                  className="w-full"
                  type="submit"
                  loading={loading}
                  loadingPosition="center"
                  loadingIndicator={
                    <CircularProgress style={{ color: "white" }} size={16} />
                  }
                  style={{
                    width: "100%",
                    marginTop: "0.8rem",
                    backgroundColor: primaryColor,
                  }}
                >
                  Continue
                </LoadingButton>
              </Form>
              <LoadingButton
                variant="contained"
                style={{
                  width: "100%",
                  marginTop: "2rem",
                  background: "white",
                  color: "black",
                  bottom: "1rem",
                  fontSize: "12px",
                  fontFamily: "Poppins",
                }}
              >
                <div className={styles.googlelog}>
                  <img alt="google" className="me-1" src={google} />
                </div>
                Sign in with Google
              </LoadingButton>
              <Typography className=" flex  ml-12 text-sm font-bold">
                Already a user?
                <Link to="/login2">
                  <Typography className="text-sm font-bold text-blue-600 underline ms-2">
                    Log in
                  </Typography>
                </Link>
              </Typography>
            </div>
          </div>

          <div className="w-full flex items-center justify-center">
            <img style={{ height: "10rem" }} alt="traceup" src={logo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
