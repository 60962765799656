import { Skeleton } from '@mui/material';
import { Typography } from 'antd';

export interface LabelValuePairProps {
  label: string;
  value: any;
}

export const LabelValuePair = (props: LabelValuePairProps) => {
  return (
    <div className="flex items-center justify-between w-full my-2">
      <Typography className="font-bold basis-2/6">{props.label}</Typography>
      <Typography className="text-left text-gray-400 basis-4/6 ">{props?.value}</Typography>
    </div>
  );
};

export const LabelValuePair2 = (props: LabelValuePairProps) => {
  return (
    <div className="flex justify-between text-sm w-full my-2">
      <div className="font-bold me-2">{props.label}</div>
      <div className="text-gray-500">{props?.value}</div>
    </div>
  );
};

export const LabelValuePairSkeleton = () => {
  return (
    <div className="flex justify-between text-sm w-full" style={{ height: '2.5rem' }}>
      <div className="w-3/5 me-2">
        <Skeleton style={{ height: '2.5rem' }} />
      </div>
      <div className="h-20 w-1/5">
        <Skeleton style={{ height: '2.5rem' }} />
      </div>
    </div>
  );
};

export const LabelValuePairCheck = (props: LabelValuePairProps) => {
  return (
    <div className="flex justify-between text-sm items-center w-full my-4">
      <div className="font-bold me-2">{props?.label}</div>
      {props?.value > 0 && (
        <div
          style={{
            padding: '0.1rem 1rem',
            borderRadius: '0.5rem',
            fontWeight: '500',
            backgroundColor: '#e6fcf0',
            color: '#00a44b',
          }}
        >
          {props.value} - Uploaded
        </div>
      )}
      {props?.value === 0 && (
        <div
          style={{
            padding: '0.1rem 1rem',
            borderRadius: '0.5rem',
            fontWeight: '500',
            backgroundColor: '#fff7f5',
            color: '#f9320d',
          }}
        >
          Missing
        </div>
      )}
    </div>
  );
};
