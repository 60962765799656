import { appActions } from "../appState";
import { getStocksByProjectId } from "../projects/constructionService";
import { addStockService, getItemsByStockId } from "./stockService";

export const addStockAction = (data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setAdded(true));
      dispatch(appActions.setFetching(true));
      await addStockService(data, token);
      dispatch(appActions.setFetching(false));
      dispatch(appActions.setAdded(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getStocksByProjectIdAction = (projectId: string) => {
  return async (dispatch: any) => {
    try {
      const res = await getStocksByProjectId(projectId);
      if (res?.status === 200) {
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getItemsByStockIdAction = (stockId: string, token: string) => {
  return async (dispatch: any) => {
    try {
      const res = await getItemsByStockId(stockId, token);
    } catch (err) {
      console.log(err);
    }
  };
};
