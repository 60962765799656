import { useDispatch, useSelector } from 'react-redux';
import store from 'store';
import { useEffect, useState } from 'react';
import GenericForm from '../../components/UI/Forms/GenericForm';
import { PaymentMethod, paymentMethods } from '../../utils/constants';
import { useForm } from 'react-hook-form';
import { getNumberWithCommas, numberWithCommas } from '../../utils/functions';
import Storage from '../../utils/Storage';
import { Client } from '../../interfaces/clients';
import { addAction, updateAction } from '../../store/app/globalActions';
import { AppState, appActions } from '../../store/appState';
import { addConsExpenseService, updateConsExpenseService } from '../../store/expenses/expensesService';

interface NewExpenseProps {
  open: boolean;
  close: Function;
  expense?: any;
}

export default function NewExpense(props: NewExpenseProps) {
  const dispatch = useDispatch();
  const [client, setClient] = useState({} as Client);
  const [paymentMethod, setPaymentMethod] = useState({} as PaymentMethod);

  const clients = useSelector((state: any) => state.app.clients);
  const appState: AppState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (appState.openForm.action === 'update') {
      setValue('description', props?.expense?.description);
      setValue('amount', numberWithCommas(props?.expense?.amount));
      setValue('paid', numberWithCommas(props?.expense?.amount));
      setValue('date', props?.expense?.date?.substring(0, 10));
      setPaymentMethod(props?.expense?.paymentMethod);
    }
  }, []);

  const onAmountChanged = () => {
    const amount = getNumberWithCommas(getValues('amount'));
    setValue('paid', numberWithCommas(amount));
  };

  const submit = handleSubmit(async (data: any) => {
    data.amount = getNumberWithCommas(data.amount);
    data.paid = getNumberWithCommas(data.paid);
    data.project = store.get('project')._id;
    if (paymentMethod) data.paymentMethod = paymentMethod.id;
    if (appState.openForm.action === 'update') {
      dispatch(
        updateAction(updateConsExpenseService, props.expense._id, data, token, () =>
          dispatch(appActions.setUpdateNeeded(true))
        )
      );
    } else {
      dispatch(addAction(addConsExpenseService, data, token, () => dispatch(appActions.setUpdateNeeded(true))));
    }
  });

  const onClientChanged = (element: Client) => {
    setClient(element);
  };

  const newExpenseElements = [
    {
      label: 'Description',
      identifier: 'description',
      inputComponent: 'input',
      type: 'text',
      placeholder: 'Description',
      register,
      errors,
      required: true,
      validationObject: {
        required: 'Required field',
      },
    },
    {
      label: 'Amount',
      identifier: 'amount',
      inputComponent: 'input',
      placeholder: 'Cash Out',
      required: true,
      register,
      errors,
      validationObject: {
        required: 'Required field',
        onChange: (e: React.FormEvent<HTMLInputElement>) => onAmountChanged(),
      },
    },
    {
      label: 'Paid / Ayishyuwe',
      identifier: 'paid',
      inputComponent: 'input',
      type: 'text',
      placeholder: 'Cash Out',
      register,
      errors,
    },
    {
      label: 'Date',
      identifier: 'date',
      inputComponent: 'date',
      register,
      errors,
      placeholder: 'Enter Start Date',
      required: true,
    },
    {
      label: 'Payment Method',
      identifier: 'paymentMethod',
      inputComponent: 'select',
      type: 'number',
      register,
      errors,
      placeholder: 'Momo / Cash',
      values: paymentMethods,
      value: paymentMethod,
      onChanged: (value: PaymentMethod) => {
        setPaymentMethod(value);
      },
      name: 'name',
      id: 'id',
    },
    {
      label: 'Supplier',
      identifier: 'supplier',
      inputComponent: 'select',
      values: clients,
      register,
      errors,
      onChanged: onClientChanged,
      value: client,
      id: '_id',
      name: 'name',
    },
  ];

  return (
    <GenericForm
      title={appState.openForm.action === 'update' ? 'Update Expense' : 'New Project Expense'}
      onSubmit={submit}
      formElements={newExpenseElements}
      style="horizontal"
      open={appState.openForm.open}
      close={() => props.close()}
    />
  );
}
