import { BrowserRouter } from "react-router-dom";
import ResponseDialog from "./components/UI/others/ResponseDialog";
import MainRouter from "./routes/MainRouter";
import React from "react";

const App = () => {
  return (
    <BrowserRouter>
      <MainRouter />
      <ResponseDialog />
    </BrowserRouter>
  );
};

export default App;
