import { useEffect } from "react";
import store from "store";
import { Avatar, Box } from "@mui/material";
import styles from "../../pages/index.module.scss";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import MetricsCard from "./MetricsCard";
import { useDispatch, useSelector } from "react-redux";
import { numberWithCommas } from "../../utils/functions";
import Storage from "../../utils/Storage";
import {
  deleteRed,
  primaryGreen,
  primaryPurple,
} from "../../assets/colors/colors";
import { getProject } from "../../store/projects/projectsActions";
import { AppState } from "../../store/appState";

const Banner = (props: any) => {
  const dispatch = useDispatch();
  const appState: AppState = useSelector((state: any) => state.app);
  const project = store.get("project");
  const updateNeeded = useSelector((state: any) => state.app.updateNeeded);
  const token = Storage.getToken();

  useEffect(() => {
    dispatch(getProject(project._id, token));
  }, [dispatch, project._id, token]);

  useEffect(() => {
    if (updateNeeded)
      dispatch(getProject(project._id, store.get("x-auth-token")));
  }, [updateNeeded, props.id, dispatch]);

  return (
    <Box className={styles.banner}>
      {/* {appState?.project?.contractAmount && ( */}
      <>
        <MetricsCard
          title="PO, VAT Exclusive"
          metric={`${numberWithCommas(
            parseInt(
              (appState?.project?.contractAmount / 1.18).toFixed(0)
            )
          )} Rwf`}
          background="#2c358d05"
          border="#2c358d14"
          avatar={
            <Avatar
              style={{
                height: "3rem",
                width: "3rem",
                backgroundColor: primaryPurple,
              }}
            >
              <AttachMoneyIcon />
            </Avatar>
          }
        />
        <MetricsCard
          title="TOTAL Expenses"
          metric={`${numberWithCommas(
            appState?.project?.totalExpenses
          )} Rwf`}
          background="#ed5e6805"
          border="#ed5e6814"
          avatar={
            <Avatar
              style={{
                height: "3rem",
                width: "3rem",
                backgroundColor: deleteRed,
              }}
            >
              <ArrowForwardIcon />
            </Avatar>
          }
        />
        <MetricsCard
          title="BALANCE"
          metric={`${numberWithCommas(
            parseInt(
              (
                appState?.project?.contractAmount / 1.18 -
                appState?.project?.totalExpenses
              ).toFixed(0)
            )
          )} Rwf`}
          background="#00a44b05"
          border="#00a44b14"
          avatar={
            <Avatar
              style={{
                height: "3rem",
                width: "3rem",
                backgroundColor: primaryGreen,
              }}
            >
              <AccountBalanceWalletIcon />
            </Avatar>
          }
        />
        <MetricsCard
          title="PO, VAT Inclusive"
          metric={`${numberWithCommas(
            parseInt(appState?.project?.contractAmount?.toFixed(0))
          )} Rwf`}
          background="#2c358d05"
          border="#2c358d14"
          avatar={
            <Avatar
              style={{
                height: "3rem",
                width: "3rem",
                backgroundColor: primaryPurple,
              }}
            >
              <AttachMoneyIcon />
            </Avatar>
          }
        />
      </>
      {/* )} */}
    </Box>
  );
};

export default Banner;
