const FileInput = (props: any) => {
  return (
    <input
      type="file"
      id="avatar"
      name="avatar"
      {...props?.element?.register(props?.element?.identifier, props?.element?.validationObject)}
      error={props?.element?.errors[`${props?.element?.identifier}`] !== undefined}
      helperText={
        props?.element?.errors[`${props?.element?.identifier}`]
          ? props?.element?.errors[`${props?.element?.identifier}`].message
          : null
      }
      accept="image/*, application/msword, application/vnd.ms-excel, text/plain, application/pdf"
      multiple
    />
  );
};

export default FileInput;
