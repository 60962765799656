import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { appActions } from "../../store/appState";
import Storage from "../../utils/Storage";
import { addAction } from "../../store/app/globalActions";
import { uploadDocumentService } from "../../store/documents/documentsService";
import GenericForm from "../../components/UI/Forms/GenericForm";

export default function NewPayrollDocument(props: any) {
  const dispatch = useDispatch();
  const project = Storage.getProject()._id;
  const token = Storage.getToken();
  const [selectedFile, setSelectedFile] = useState(null as unknown);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFileInputChange = (e: any) => {
    // @ts-ignore: Object is possibly 'null'.
    const file = (e.target as HTMLInputElement).files[0];
    setSelectedFile(file);
  };

  const newDocumentElements = [
    {
      label: "Date From",
      identifier: "fromDate",
      inputComponent: "date",
      register,
      errors,
      placeholder: "Kuva",
      required: true,
    },
    {
      label: "Date To",
      identifier: "toDate",
      inputComponent: "date",
      register,
      errors,
      placeholder: "Kugeza",
      required: true,
    },
    {
      label: "Amount",
      inputComponent: "input",
      identifier: "amount",
      register,
      errors,
      required: true,
      validationObject: {
        required: "Required field",
      },
    },
    {
      label: "File",
      identifier: "file",
      inputComponent: "file",
      type: "file",
      register,
      errors,
      required: true,
      placeholder: "Choose File",
      validationObject: {
        onChange: handleFileInputChange,
      },
    },
  ];

  const handleSubmitFile = handleSubmit(async (data: any) => {
    try {
      if (!selectedFile) return;
      let formdata = new FormData();
      let payrollDates = {
        from: data.fromDate,
        to: data.toDate,
        amount: +data.amount,
      };
      formdata.append("file", selectedFile as File);
      formdata.append("type", "payroll");
      formdata.append("payroll", JSON.stringify(payrollDates));
      formdata.append("project", project);
      dispatch(
        addAction(uploadDocumentService, formdata, token, () => {
          props.close();
          dispatch(appActions.setFetchedPayroll(false));
        })
      );
    } catch (error: any) {
      console.log(error.response.data.message);
    }
  });

  return (
    <GenericForm
      title="Upload New Payroll Document"
      onSubmit={handleSubmitFile}
      formElements={newDocumentElements}
      style="vertical"
      open={props.open}
      close={() => props.close()}
    />
  );
}
