import { createSlice } from "@reduxjs/toolkit";
import { CompanyExpense } from "../interfaces/companyExpense";
import { ExpenseCategory } from "../interfaces/expenseCategory";
import { Notification } from "../interfaces/Notification";
import { Report } from "../interfaces/report";
import { Project } from "../interfaces/project";
import { Expense } from "../interfaces/expense";
import { Client } from "../interfaces/clients";
import { Company } from "../interfaces/company";

export interface AppState {
  updateNeeded: boolean;
  isFetching: boolean;
  drawerFetching: boolean;
  fetchingFormItems: boolean;
  isSubmitting: boolean;
  isDeleting: boolean;
  isExportingExcel: boolean;
  fetchedProjects: boolean;
  selectedProject: any;
  projectId: string;
  count: number;
  reports: Report[];
  fetchedReports: boolean;
  httpResponse: any;
  companyExpenses: {
    fetched: boolean;
    total: number;
    expenses: CompanyExpense[];
    stats: any;
  };
  expenseCategories: ExpenseCategory[];
  openForm: {
    open: boolean;
    action: string;
  };
  chartData: any;
  notifications: Notification[];
  fetchedNotifications: boolean;
  projects: Project[];
  projectsStats: any;
  fetchedProjectsStats: boolean;
  project: any;
  added: boolean;
  fetching: boolean;
  fetchedImages: boolean;
  fetchedDocuments: boolean;
  fetchedPayroll: boolean;
  fetchedClients: boolean;
  expenses: Expense[];
  payments: [];
  filter: string;
  manPower: [];
  documents: [];
  documentsStats: any;
  fetchingDocumentStats: boolean;
  fetchedDocumentStats: boolean;
  payrolls: [];
  images: any[];
  clients: Client[];
  company: Company | undefined;
  stock: [];
  stockItems: [];
  settings: [];
}

const appSlice = createSlice({
  name: "projects",
  initialState: {
    updateNeeded: false,
    isFetching: false,
    drawerFetching: false,
    fetchingFormItems: false,
    isSubmitting: false,
    isDeleting: false,
    isExportingExcel: false,
    fetchedProjects: false,
    fetchedReports: false,
    count: 0,
    reports: [],
    companyExpenses: {
      total: 0,
      expenses: [],
      fetched: false,
      stats: [],
    },
    expenseCategories: [],
    notifications: [],
    fetchedNotifications: false,
    openForm: {
      open: false,
      action: "",
    },
    chartData: {},
    httpResponse: {
      statusCode: 0,
      message: "",
      status: "",
    },
    projects: [],
    projectsStats: {},
    fetchedProjectsStats: false,
    projectId: "",
    project: {},
    selectedProject: {},
    added: false,
    fetching: false,
    fetchedImages: false,
    fetchedDocuments: false,
    fetchedPayroll: false,
    fetchedClients: false,
    expenses: [],
    payments: [],
    stock: [],
    stockItems: [],
    filter: "",
    manPower: [],
    documents: [],
    documentsStats: {},
    fetchingDocumentStats: false,
    fetchedDocumentStats: false,
    payrolls: [],
    images: [],
    settings: [],
    clients: [],
    company: undefined,
  } as AppState,
  reducers: {
    changeSelectedProject(state, action) {
      state.selectedProject = action.payload.project;
    },
    setUpdateNeeded(state, action) {
      state.updateNeeded = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setDrawerIsFetching(state, action) {
      state.drawerFetching = action.payload;
    },
    setIsSubmitting(state, action) {
      state.isSubmitting = action.payload;
    },
    setFetchingFormItems(state, action) {
      state.fetchingFormItems = action.payload;
    },
    setIsDeleting(state, action) {
      state.isDeleting = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload.count;
    },
    setFetchedCompanyExpenses(state, action) {
      state.companyExpenses.fetched = action.payload;
    },
    setFetchedProjects(state, action) {
      state.fetchedProjects = action.payload;
    },
    setCompanyExpenses(state, action) {
      state.companyExpenses = {
        total: action.payload.total,
        expenses: action.payload.expenses,
        fetched: true,
        stats: action.payload.stats,
      };
    },
    setExpensesCategories(state, action) {
      state.expenseCategories = action.payload;
    },
    resetError(state) {
      state.httpResponse.statusCode = 0;
      state.httpResponse.message = "";
    },
    setHttpResponse(state, action) {
      state.httpResponse.statusCode = action.payload.statusCode;
      state.httpResponse.status = action.payload.status;
      state.httpResponse.message = action.payload.message;
    },
    setOpenForm(state, action) {
      state.openForm = {
        open: action.payload.open,
        action: action.payload.action,
      };
    },
    closeForm(state) {
      state.openForm = { open: false, action: "" };
    },
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
    setFetchedNotifications(state, action) {
      state.fetchedNotifications = action.payload;
    },
    setIsExportingExcel(state, action) {
      state.isExportingExcel = action.payload;
    },
    setReports(state, action) {
      state.reports = action.payload;
    },
    setFetchedReports(state, action) {
      state.fetchedReports = action.payload;
    },
    setFetching(state, action) {
      state.fetching = action.payload;
    },
    setFetchedImages(state, action) {
      state.fetchedImages = action.payload;
    },
    setFetchedDocuments(state, action) {
      state.fetchedDocuments = action.payload;
    },
    setFetchedPayroll(state, action) {
      state.fetchedPayroll = action.payload;
    },
    setFetchedClients(state, action) {
      state.fetchedClients = action.payload;
    },
    setAdded(state, action) {
      state.added = action.payload;
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
    setProjectId(state, action) {
      state.projectId = action.payload;
    },
    setProjects(state, action) {
      state.projects = action.payload.projects;
    },
    setProjectsStats(state, action) {
      state.projectsStats = action.payload;
    },
    setFetchedProjectsStats(state, action) {
      state.fetchedProjectsStats = action.payload.projects;
    },
    setSelectedProject(state, action) {
      state.selectedProject = action.payload;
    },
    setProject(state, action) {
      state.project = action.payload;
    },
    setExpenses(state, action) {
      state.expenses = action.payload.expenses;
    },
    setDocuments(state, action) {
      state.documents = action.payload;
    },
    setDocumentsStats(state, action) {
      state.documentsStats = action.payload;
    },
    setFetchedDocumentsStats(state, action) {
      state.fetchedDocumentStats = action.payload;
    },
    setFetchingDocumentsStats(state, action) {
      state.fetchingDocumentStats = action.payload;
    },
    setPayrolls(state, action) {
      state.payrolls = action.payload;
    },
    setImages(state, action) {
      state.images = action.payload;
    },
    setPayments(state, action) {
      state.payments = action.payload.payments;
    },
    setClients(state, action) {
      state.clients = action.payload;
    },
    setChartData(state, action) {
      state.chartData = {
        labels: action?.payload?.map((data: any) => data._id),
        datasets: [
          {
            label: "Profit",
            data: action?.payload?.map((data: any) => data?.profit),
            backgroundColor: "#5de89c",
            borderColor: "orange",
            borderWidth: 2,
            tension: 0.4,
            fill: true,
          },
        ],
      };
    },
    setCompany(state, action) {
      state.company = action.payload;
    },
    resetAppState(state) {
      state.fetchedImages = false;
      state.fetchedDocuments = false;
      state.fetchedPayroll = false;
      state.fetchedClients = false;
      state.project = {};
      state.fetchedDocumentStats = false;
    },
  },
});

export const appActions = appSlice.actions;

export default appSlice.reducer;
