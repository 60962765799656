import { setResponseAction } from "../../utils/functions";
import { appActions } from "../appState";

export const addAction = (
  service: Function,
  data: any,
  token: string,
  refresh: Function,
  onSuccess: Function = () => {}
) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsSubmitting(true));
    const res = await service(data, token);
    if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
      dispatch(setResponseAction(res));
    } else {
      dispatch(setResponseAction(res, 'success', res.message));
      onSuccess();
    }
    dispatch(appActions.setIsSubmitting(false));
    dispatch(appActions.setOpenForm({ open: false, action: '' }));
    refresh();
  };
};

export const updateAction = (service: Function, id: string, data: any, token: string, refresh: Function) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsSubmitting(true));
    const res = await service(id, data, token);
    if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
      dispatch(setResponseAction(res));
    } else {
      dispatch(setResponseAction(res, 'success', res.message));
    }
    dispatch(appActions.setIsSubmitting(false));
    dispatch(appActions.setOpenForm({ open: false, action: '' }));
    refresh();
  };
};

export const deleteAction = (service: Function, itemId: string, token: string, entity: string, refresh: Function) => {
  return async (dispatch: any) => {
    dispatch(appActions.setIsSubmitting(true));
    const res = await service(itemId, token);
    if (res.response?.data.status === 'fail' || res.response?.data.status === 'error') {
      dispatch(setResponseAction(res));
    } else {
      dispatch(setResponseAction(res, 'success', `${entity} deleted Successfully!`));
    }
    dispatch(appActions.setIsSubmitting(false));
    dispatch(appActions.setIsDeleting(false));
    refresh();
  };
};
