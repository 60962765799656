export interface projectColumn {
  id: 'name' | 'client' | 'startDate' | 'po' | 'status' | 'action';
  label: String;
  minWidth?: number;
  align?: 'center' | 'left';
}
export interface stockItemColumn {
  id: 'name' | 'measurement' | 'quantity' | 'savingStatus' | 'action';
  label: String;
  minWidth?: number;
  align?: 'center';
}

export interface usersColumn {
  id: 'index' | 'name' | 'nid' | 'phone' | 'email' | 'role' | 'action';
  label: String;
  minWidth?: number;
  align?: 'center';
}

export const projectColumns: projectColumn[] = [
  { id: 'name', label: 'Name', align: 'center' },
  { id: 'client', label: 'Client', align: 'center' },
  { id: 'startDate', label: 'Start Date', align: 'center' },
  { id: 'po', label: 'P.O Amount', align: 'center' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

export const usersColumns: usersColumn[] = [
  { id: 'index', label: 'No', align: 'center' },
  { id: 'name', label: 'Names', align: 'center' },
  { id: 'nid', label: 'National ID', align: 'center' },
  { id: 'phone', label: 'Tel Number', align: 'center' },
  { id: 'email', label: 'E-mail', align: 'center' },
  { id: 'role', label: 'Role', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

export const stockItemColumns: stockItemColumn[] = [
  { id: 'name', label: 'Product Name', align: 'center' },
  { id: 'measurement', label: 'Unity', align: 'center' },
  { id: 'quantity', label: 'Qty', align: 'center' },
  // { id: 'po', label: 'P.O Amount', align: 'center' },
  { id: 'savingStatus', label: 'Status', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];
export interface projecTabletData {
  _id: string;
  name: any;
  projectName: string;
  client: string;
  startDate: string;
  po: string;
  status: any;
  action: any;
}

export interface stockItem {
  id: string;
  savingStatus: any;
  name: string;
  measurement: string;
  quantity: number;
  stock: any;
  action: any;
}

export interface userItem {
  index: number;
  id?: string;
  nid: string;
  name: string;
  phone: string;
  email: string;
  role: string;
  item?: any;
  action: any;
  savingStatus: any;
}
