import { Box, Button, CircularProgress, Dialog, DialogContent } from '@mui/material';
import styles from '../../index.module.scss';
import FormInputs from '../Inputs/FormInputs';
import FormHeader from './FormHeader';
import { useSelector } from 'react-redux';
import { FormEventHandler } from 'react';

interface GenericFormProps {
  open: boolean;
  close: Function;
  title: string;
  formElements: any;
  onSubmit: FormEventHandler;
  style?: string;
}

const GenericForm = (props: GenericFormProps) => {
  const isSubmitting = useSelector((state: any) => state.app.isSubmitting);

  return (
    <Dialog
      classes={{ paper: props?.style === 'vertical' ? styles.genericFormVertical : styles.genericForm }}
      open={props.open}
      onClose={() => props.close()}
    >
      <FormHeader title={props.title} close={props.close} />
      <DialogContent>
        <form onSubmit={props?.onSubmit}>
          <Box className={styles.genericForm_column}>
            {props.formElements.map((element: any, index: number) => {
              return <FormInputs key={index} element={element} style={props?.style} />;
            })}
          </Box>
          <Box className={styles.center}>
            {isSubmitting && (
              <Button className={styles.confirmButton} variant="contained">
                <CircularProgress size={30} color="inherit" className={styles.circularProgress} />
              </Button>
            )}

            {!isSubmitting && (
              <Button type="submit" className={styles.confirmButton} variant="contained">
                Save
              </Button>
            )}
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default GenericForm;
