import { useDispatch, useSelector } from "react-redux";
import GenericForm from "../../components/UI/Forms/GenericForm";
import { useForm } from "react-hook-form";
import Storage from "../../utils/Storage";
import { appActions } from "../../store/appState";
import { useEffect } from "react";
import { addAction, updateAction } from "../../store/app/globalActions";
import { addClientService, updateClientService } from "../../store/clients/clientsService";

export default function NewClient(props: any) {
  const dispatch = useDispatch();
  const appState = useSelector((state: any) => state.app);
  const token = Storage.getToken();
  const user = useSelector((state: any) => state.auth.user);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (appState.openForm.action === "update") {
      setValue("name", props?.client?.name);
      setValue("representative", props?.client?.representative);
      setValue("email", props?.client?.email);
      setValue("contact", props?.client?.contact);
      setValue("tinNumber", props?.client?.tinNumber);
      setValue("location", props?.client?.location);
    }
  }, []);

  const newProjectElements = [
    {
      label: "Name",
      identifier: "name",
      inputComponent: "input",
      type: "text",
      placeholder: "Enter Client Name",
      required: true,
      register,
      errors,
      validationObject: {
        required: "Required field",
      },
    },
    {
      label: "Representative",
      identifier: "representative",
      inputComponent: "input",
      type: "text",
      register,
      errors,
      placeholder: "Enter Name of Representative",
    },
    {
      label: "E-mail",
      identifier: "email",
      inputComponent: "input",
      type: "text",
      register,
      errors,
      placeholder: "Client Main Email",
    },
    {
      label: "Client Phone number",
      identifier: "contact",
      inputComponent: "input",
      register,
      errors,
      placeholder: "Enter Primary Contact",
    },
    {
      label: "Tin Number",
      identifier: "tinNumber",
      type: "number",
      inputComponent: "input",
      register,
      errors,
      placeholder: "Enter Client's Tin Number",
    },
    {
      label: "Address",
      identifier: "location",
      inputComponent: "input",
      register,
      errors,
      placeholder: "Enter Client's Address, Office Location",
    },
  ];

  const submit = handleSubmit(async (data: any) => {
    if (data.tinNumber) data.tinNumber = +data.tinNumber;
    if (!data.representative) delete data.representative;
    if (!data.email) delete data.email;
    if (!data.contact) delete data.contact;
    if (!data.tinNumber) delete data.tinNumber;
    if (!data.location) delete data.location;
    data.company = user.company._id;
    if (appState.openForm.action === "update") {
      dispatch(
        updateAction(updateClientService, props.client._id, data, token, () =>
          dispatch(appActions.setFetchedClients(false))
        )
      );
    } else {
      dispatch(
        addAction(addClientService, data, token, () =>
          dispatch(appActions.setFetchedClients(false))
        )
      );
    }
  });

  return (
    <GenericForm
      title={
        appState.openForm.action === "update" ? "Update Client" : "New Client"
      }
      onSubmit={submit}
      formElements={newProjectElements}
      style="horizontal"
      open={appState.openForm.open}
      close={() => {
        dispatch(appActions.closeForm());
      }}
    />
  );
}
