import { Typography } from '@mui/material';
import Layout from '../../components/UI/others/Layout';
import ConstructionNavMenu from '../../components/NavMenu/ConstructionNavMenu';
import styles from '../index.module.scss';

const ConstructionSuppliers = () => {
  return (
    <Layout navMenu={<ConstructionNavMenu select={6} />}>
      <Typography className={styles.header}>ConstructionSuppliers</Typography>
    </Layout>
  );
};

export default ConstructionSuppliers;
