import ProjectsStats from './ProjectsStats';

const ProjectsOverview = () => {
  return (
    <>
      <ProjectsStats />
    </>
  );
};

export default ProjectsOverview;
