import { List } from "@mui/material";
import { Link } from "react-router-dom";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import HomeIcon from "@mui/icons-material/Home";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import styles from "./index.module.scss";
import ImageIcon from "@mui/icons-material/Image";
import NavItem from "./NavItem";

const ConstructionNavMenu = (props: any) => {
  const { select } = props;

  const NavItems = [
    { label: "HOME", icon: <HomeIcon />, path: "/" },
    {
      label: "Expenses",
      icon: <MoneyOffIcon style={{ color: "currentColor" }} />,
      path: "/project/expenses",
    },
    {
      label: "Stock",
      icon: <ShowChartIcon style={{ color: "currentColor" }} />,
      path: `/stock`,
    },
    {
      label: "PayRoll",
      icon: <ReceiptLongIcon style={{ color: "currentColor" }} />,
      path: "/payroll",
    },
    {
      label: "Documents",
      icon: <DescriptionIcon style={{ color: "currentColor" }} />,
      path: "/documents",
    },
    {
      label: "Images",
      icon: <ImageIcon style={{ color: "currentColor" }} />,
      path: "/images",
    },
    {
      label: "Suppliers",
      icon: <PeopleAltIcon style={{ color: "currentColor" }} />,
      path: "/suppliers",
    },
  ];
  return (
    <List>
      {NavItems.map((nav, index) => (
        <Link key={index} to={nav.path} className={styles.link}>
          <NavItem nav={nav} selected={index === select} />
        </Link>
      ))}
    </List>
  );
};

export default ConstructionNavMenu;
