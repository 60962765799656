import { TextField } from '@mui/material';
import styles from '../../index.module.scss';

const FormDatePicker = (props: any) => {
  return (
    <TextField
      id="date"
      type="date"
      inputRef={props?.reference}
      defaultValue={`${new Date().toISOString().substring(0, 10)}`}
      className={styles.dateInput}
      classes={{ root: styles.dateInputRoot }}
      InputLabelProps={{
        shrink: true,
      }}
      {...props?.element?.register(props?.element?.identifier, props?.element?.validationObject)}
      error={props?.element?.errors[`${props?.element?.identifier}`] !== undefined}
      helperText={
        props?.element?.errors[`${props?.element?.identifier}`]
          ? props?.element?.errors[`${props?.element?.identifier}`].message
          : null
      }
    />
  );
};

export default FormDatePicker;
