import { Menu, MenuItem } from '@mui/material';
import styles from '../index.module.scss';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../../utils/constants';
import Layout from '../../components/UI/others/Layout';
import HomeNavMenu from '../../components/NavMenu/HomeNavMenu';
import { useDispatch, useSelector } from 'react-redux';
import Visibility from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import Storage from '../../utils/Storage';
import { useEffect, useState } from 'react';
import LoadingContainer from '../../components/UI/others/LoadingContainer';
import TableComponent from '../../components/UI/others/TableComponent';
import { AppState, appActions } from '../../store/appState';
import { getReportsAction } from '../../store/report/reportActions';
import { ReportRow, reportsColumns } from '../../interfaces/report';

const Reports = () => {
  const appState: AppState = useSelector((state: any) => state.app);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [report, setReport] = useState<any>(null);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const token = Storage.getToken();

  useEffect(() => {
    if (!appState.fetchedReports) {
      dispatch(getReportsAction(`?business=construction`, token));
    }
  }, []);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    dispatch(appActions.setFetchedReports(false));
  };

  const handleRowsPerPageChange = (event: any) => {
    setCount(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createData = (report: any): ReportRow => {
    return {
      id: report._id,
      date: report?.period?.endDate?.substring(0, 10),
      name: report.name,
      fileType: 'PDF',
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setReport(report);
            setAnchorEl(event.currentTarget);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = appState.reports?.map((report: any) => createData(report));

  return (
    <Layout title={`REPORTS`} navMenu={<HomeNavMenu />}>
      <div className="mt-8"></div>
      {/* <Link to={`${SERVER_URL}/project/report/pdf`}>
        <Button variant="contained">Monthly Report</Button>
      </Link> */}

      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <div>
          <TableComponent
            rows={rows}
            columns={reportsColumns}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handleChangePage}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {Boolean(anchorEl) && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem>
                <Link to={`${SERVER_URL}/project/report/${report?._id}`}>
                  <Visibility onClick={() => setAnchorEl(null)} style={{ marginRight: '0.2rem' }} /> View
                </Link>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(appActions.setIsDeleting(true));
                }}
              >
                <DeleteIcon />
                Delete
              </MenuItem>
            </Menu>
          )}
        </div>
      )}
    </Layout>
  );
};

export default Reports;
