import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TopActions from '../../components/UI/others/TopActions';
import { Box, Menu, MenuItem } from '@mui/material';
import styles from '../index.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Layout from '../../components/UI/others/Layout';
import Storage from '../../utils/Storage';
import HomeNavMenu from '../../components/NavMenu/HomeNavMenu';
import NewExpense from './NewCompanyExpense';
import { CompanyExpense, CompanyExpenseRow, companyExpensesColumns } from '../../interfaces/companyExpense';
import { numberWithCommas, renderMoney } from '../../utils/functions';
import TableComponent from '../../components/UI/others/TableComponent';
import LoadingContainer from '../../components/UI/others/LoadingContainer';
import NoData from '../../components/UI/others/NoData';
import { Avatar, DatePicker, DatePickerProps, Space, Typography } from 'antd';
import ConfirmModal from '../../components/UI/others/ConfirmModal';
import { AppState, appActions } from '../../store/appState';
import { exportCompanyExpenses, getCompanyExpenses, searchCompanyExpensesAction } from '../../store/companyexpenses/companyExpenseActions';
import { deleteAction } from '../../store/app/globalActions';
import { deleteCompanyExpenseService } from '../../store/companyexpenses/companyExpenseService';

const CompanyExpenses = () => {
  const [searchText, setSearchText] = useState('');
  const [isInit, setIsInit] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [month, setMonth] = useState(new Date().toISOString().substring(0, 7));
  const [companyExpense, setCompanyExpense] = useState({} as any);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const dispatch = useDispatch();
  const token = Storage.getToken();
  const user = Storage.getUser();
  const appState: AppState = useSelector((state: any) => state.app);
  // const { companyExpenses, count: expenseCount, isFetching } = useSelector((state: any) => state.companyExpenses); // modified selector

  useEffect(() => {
    if (!appState.companyExpenses.fetched) {
      dispatch(getCompanyExpenses(`?company=${user?.company?._id}&month=${month}-01`, token));
    }
  }, [appState.companyExpenses.fetched]);

  const handleSearch = (value: string) => {
    setSearchText(value);
    setPage(0);
    dispatch(searchCompanyExpensesAction(value, token));
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    if (dateString) {
      setMonth(dateString);
      console.log('Changedv ', dateString);
      dispatch(appActions.setFetchedCompanyExpenses(false));
    }
  };

  const exportExcelHandler = () => {
    dispatch(
      exportCompanyExpenses(`?company=${user?.company?._id}&month=${month}-01`, token, `${month} CEMS expenses`)
    );
  };

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    dispatch(appActions.setFetchedCompanyExpenses(false));
  };

  const handleRowsPerPageChange = (event: any) => {
    setCount(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createData = (companyExpense: CompanyExpense): CompanyExpenseRow => {
    return {
      id: companyExpense?._id,
      date: companyExpense?.date?.substring(0, 10),
      description: companyExpense.description,
      amount: `${numberWithCommas(companyExpense?.amount)} RWF`,
      category: companyExpense?.category?.name,
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setCompanyExpense(companyExpense);
            handleClick(event);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = appState?.companyExpenses.expenses?.map((companyExpense: CompanyExpense) => createData(companyExpense));

  const colors = ['bg-lime-600', 'bg-rose-500', 'bg-yellow-500', 'bg-emerald-500', 'bg-orange-500', 'bg-green-500'];

  const handleDeleteExpense = () => {
    dispatch(
      deleteAction(deleteCompanyExpenseService, companyExpense._id, token, 'Company Expense', () =>
        dispatch(appActions.setFetchedCompanyExpenses(false))
      )
    );
  };

  return (
    <Layout title="Company Expenses" navMenu={<HomeNavMenu select={4} />}>
      {appState.isFetching && <LoadingContainer />}
      {!appState.isFetching && (
        <>
          {appState?.companyExpenses.expenses?.length > 0 && (
            <div className="w-full rounded p-4 bg-white my-8">
              <div className="flex justify-between">
                <div className="flex ms-12 font-bold">
                  <Typography className="me-3">Total: </Typography>
                  <Typography>{renderMoney(appState?.companyExpenses?.total)}</Typography>
                </div>

                <Space className="me-12">
                  <Typography className="text-base text-gray-400">Choose Month: </Typography>
                  <DatePicker onChange={onChange} picker="month" />
                </Space>
              </div>

              <div className="expense_card w-11/12  mx-auto my-4 px-6 rounded-lg flex justify-around border">
                {appState?.companyExpenses.stats?.map((d: any, index: number) => (
                  <div className="border-r px-2 py-6 mx-auto w-full" key={index}>
                    <Box className="flex items-center justify-center mx-auto w-full" key={d.name}>
                      <Avatar className={`${colors[index]} me-2`} size={10}></Avatar>
                      <Typography className="text-sm font-bolder text-gray-400">{d.name}</Typography>
                    </Box>
                    <Typography className="text-base mt-1 font-bold text-center">{renderMoney(d.total)}</Typography>
                  </div>
                ))}
              </div>
            </div>
          )}
          <TopActions
            setNew={() => dispatch(appActions.setOpenForm({ open: true, action: 'add' }))}
            entity="Company Expense"
            addNew={true}
            export={exportExcelHandler}
          />
          {appState?.companyExpenses?.expenses?.length > 0 && (
            <TableComponent
              rows={rows}
              columns={companyExpensesColumns}
              rowClickedHandler={() => {}}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              handlePageChange={handleChangePage}
              handleRowsPerPageChange={handleRowsPerPageChange}
            />
          )}
          {appState?.companyExpenses?.expenses?.length === 0 && <NoData />}
          {appState.openForm.open && (
            <NewExpense
              open={appState.openForm.open}
              companyExpense={companyExpense}
              close={() => dispatch(appActions.closeForm())}
            />
          )}
          {open && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(appActions.setOpenForm({ open: true, action: 'update' }));
                }}
              >
                <AutorenewIcon />
                <Typography> Update</Typography>
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  setAnchorEl(null);
                  dispatch(appActions.setIsDeleting(true));
                }}
              >
                <DeleteIcon /> <Typography> Delete</Typography>
              </MenuItem>
            </Menu>
          )}
          {appState.isDeleting && (
            <ConfirmModal
              onConfirm={handleDeleteExpense}
              content={`Please confirm to delete ${companyExpense.description} expense`}
            />
          )}
        </>
      )}
    </Layout>
  );
};

export default CompanyExpenses;
