import { setResponseAction } from "../../utils/functions";
import { appActions } from "../appState";
import { addClientService, getClientsService } from "./clientsService";

export const getClients = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getClientsService(query, token);
      if (
        res.response?.data.status === "fail" ||
        res.response?.data.status === "error"
      ) {
        dispatch(setResponseAction(res));
      } else {
        dispatch(appActions.setClients(res.data));
        dispatch(appActions.setFetchedClients(true));
      }
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const addClient = (data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      await addClientService(data, token);
      dispatch(appActions.setIsFetching(false));
      dispatch(appActions.setAdded(true));
      dispatch(appActions.setUpdateNeeded(true));
    } catch (err) {
      console.log(err);
    }
  };
};
