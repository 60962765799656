import axios from "axios";
import { SERVER_URL } from "../../utils/constants";
import { setResponseAction } from "../../utils/functions";
import { appActions } from "../appState";
import {
  getDocumentsService,
  getDocumentsStatsService,
} from "./documentsService";

export const getImages = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getDocumentsService(query, token);
      if (
        res.response?.data.status === "fail" ||
        res.response?.data.status === "error"
      ) {
        dispatch(setResponseAction(res));
      } else {
        dispatch(appActions.setImages(res.data));
      }
      dispatch(appActions.setIsFetching(false));
      dispatch(appActions.setFetchedImages(true));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDocuments = (
  query: string,
  token: string,
  refresh: Function
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getDocumentsService(query, token);
      if (
        res.response?.data.status === "fail" ||
        res.response?.data.status === "error"
      ) {
        dispatch(setResponseAction(res));
      } else {
        dispatch(appActions.setDocuments(res.data));
      }

      dispatch(appActions.setIsFetching(false));
      refresh();
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDocumentsStatsAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      console.log("query ", query);
      console.log("token ", token);
      dispatch(appActions.setFetchingDocumentsStats(true));
      const res = await getDocumentsStatsService(query, token);
      if (
        res.response?.data.status === "fail" ||
        res.response?.data.status === "error"
      ) {
        dispatch(setResponseAction(res));
      } else {
        dispatch(appActions.setDocumentsStats(res.data));
        dispatch(appActions.setFetchedDocumentsStats(true));
      }
      dispatch(appActions.setFetchingDocumentsStats(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPayrolls = (
  query: string,
  token: string,
  refresh: Function
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getDocumentsService(query, token);
      console.log("response ", res);
      if (
        res.response?.data.status === "fail" ||
        res.response?.data.status === "error"
      ) {
        dispatch(setResponseAction(res));
      } else {
        dispatch(appActions.setPayrolls(res.data));
      }

      dispatch(appActions.setIsFetching(false));
      refresh();
    } catch (err) {
      console.log(err);
    }
  };
};

export const addPurchaseOrder = () => {
  return async (dispatch: any) => {
    try {
      // await addPurchaseOrderService()
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPayroll = (query: String, query2: String) => {
  return async (dispatch: any) => {
    try {
      var response = await axios({
        method: "GET",
        url: `${SERVER_URL}/project/Projects${query}`,
      });
      var employees = response.data.data[0].employees;
      for (var employee in employees) {
        var emp = employees[employee];
        await axios({
          method: "GET",
          url: `${SERVER_URL}/construction/payroll?employee=${emp}&sort=date&${query2}`,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};
