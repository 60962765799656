import Typography from 'antd/es/typography/Typography';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Storage from '../../utils/Storage';
import moment from 'moment';
import { renderMoney } from '../../utils/functions';
import { User } from '../../interfaces/user';
import { getConsProjectsStats } from '../../store/projects/projectsActions';
import { AppState } from '../../store/appState';

const ProjectsStats = () => {
  const token = Storage.getToken();
  const user: User = Storage.getUser();
  const dispatch = useDispatch();
  const [init, setInit] = useState(true);
  const appState: AppState = useSelector((state: any) => state.app);

  useEffect(() => {
    if (!appState.fetchedProjectsStats && init) {
      dispatch(getConsProjectsStats(`?company=${user?.company?._id}`, token));
      setInit(false);
    }
  }, [appState.fetchedProjectsStats]);

  return (
    <div className="p-5 w-full rounded-md flex content-around">
      <div className="bg-white rounded-md flex flex-col items-center justify-center py-4 px-8 me-8 shadow-md">
        <Typography className="text-4xl font-bold text-purple-900 mb-2">
          {appState?.projectsStats?.open + appState?.projectsStats?.closed}
        </Typography>
        <Typography className="text-sm text-gray-500 font-bold mb-3">Total Projects</Typography>
      </div>
      <div className="bg-white rounded-md flex flex-col items-center justify-center py-4 px-8 me-8 shadow-md">
        <Typography className="text-4xl font-bold text-orange-600 mb-2">
          {appState?.projectsStats?.closed}
        </Typography>
        <Typography className="text-sm text-gray-500 font-bold mb-3">Closed Projects</Typography>
      </div>
      <div className="bg-white rounded-md flex flex-col items-center justify-center me-8 py-4 px-8 shadow-md">
        <Typography className="text-4xl font-bold text-green-600 mb-2">
          {appState?.projectsStats?.open}
        </Typography>
        <Typography className="text-sm text-gray-500 font-bold mb-3">Open Projects</Typography>
      </div>
      <div
        className="bg-white rounded-md flex flex-col items-center justify-center me-8 py-4 px-3 shadow-md"
        style={{ width: '15vw' }}
      >
        <Typography className="text-center text-sm text-gray-500 font-bold mb-2">Site Imaze Igihe Kinini (Open)</Typography>
        <Typography className="text-base font-bold mb-1 text-center">{appState?.projectsStats?.longest?.name}</Typography>
        <Typography className="text-sm text-purple-900 font-bold mb-2">
          {moment.duration(moment().diff(moment(appState?.projectsStats?.longest?.startDate))).humanize()}
        </Typography>
      </div>
      <div
        className="bg-white rounded-md flex flex-col items-center justify-center me-8 py-4 px-3 shadow-md"
        style={{ width: '15vw' }}
      >
        <Typography className="text-center text-sm text-gray-500 font-bold mb-2">Site Ifite PO Nini (Open)</Typography>
        <Typography className="text-base text-center font-bold mb-1">{appState?.projectsStats?.largest?.name}</Typography>
        <Typography className="text-sm text-purple-900 font-black mb-2">{renderMoney(appState?.projectsStats?.largest?.contractAmount)}</Typography>
      </div>
    </div>
  );
};

export default ProjectsStats;
