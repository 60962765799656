import { SERVER_URL } from "../../utils/constants";
import {
  exportExcel,
  numberWithCommas,
  setResponseAction,
} from "../../utils/functions";
import HttpRequest from "../../utils/HttpRequest";
import { appActions } from "../appState";
import {
  addCompanyExpenseService,
  getCompanyExpenseService,
  getExpenseCategoriesService,
  searchCompanyExpenseService,
} from "./companyExpenseService";

export const createCompanyExpense = async (data: any, token: string) => {
  try {
    await addCompanyExpenseService(data, token);
  } catch (err: any) {
    console.log(err.response.data.message);
  }
};

export const updateCompanyExpense = async (
  id: String,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/project/companyexpenses/${id}`,
    data,
    token
  );
};

export const getCompanyExpenses = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getCompanyExpenseService(query, token);
      if (
        res.response?.data.status === "fail" ||
        res.response?.data.status === "error"
      ) {
        dispatch(setResponseAction(res));
      } else {
        const total = res?.data?.stats[res?.data?.stats?.length - 1];
        res?.data?.stats?.splice(res?.data?.stats?.length - 1);
        dispatch(
          appActions.setCompanyExpenses({
            total: total?.total ?? 0,
            expenses: res.data.data,
            stats: res?.data?.stats,
          })
        );
        dispatch(appActions.setCount({ count: res.count }));
        dispatch(appActions.setFetchedCompanyExpenses(true));
      }
      dispatch(appActions.setUpdateNeeded(false));
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log("error: ", err);
    }
  };
};

export const exportCompanyExpenses = (
  query: string,
  token: string,
  excelName: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsExportingExcel(true));
      const res = await getCompanyExpenseService(query, token);
      if (
        res.response?.data.status === "fail" ||
        res.response?.data.status === "error"
      ) {
        dispatch(setResponseAction(res));
      } else {
        let data = res.data.data.map((data: any) => {
          return {
            Date: data?.date?.substring(0, 10),
            Description: data?.description,
            Category: data?.category?.name,
            Amount: numberWithCommas(data?.amount),
          };
        });
        exportExcel(data, excelName);
      }
      dispatch(appActions.setIsExportingExcel(false));
    } catch (err) {
      console.log("error: ", err);
    }
  };
};

export const getExpenseCategoriesAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setFetchingFormItems(true));
      const res = await getExpenseCategoriesService(query, token);
      if (
        res.response?.data.status === "fail" ||
        res.response?.data.status === "error"
      ) {
        dispatch(setResponseAction(res));
      } else {
        dispatch(appActions.setExpensesCategories(res.data));
      }
      dispatch(appActions.setFetchingFormItems(false));
    } catch (err) {
      console.log("error: ", err);
    }
  };
};

export const searchCompanyExpensesAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchCompanyExpenseService(query, token);
      if (
        res.response?.data.status === "fail" ||
        res.response?.data.status === "error"
      ) {
        dispatch(setResponseAction(res));
      } else {
        dispatch(appActions.setCompanyExpenses({ expenses: res.data }));
        dispatch(appActions.setCount({ count: res.count }));
      }
      dispatch(appActions.setUpdateNeeded(false));
      dispatch(appActions.setIsFetching(false));
    } catch (err) {
      console.log("error: ", err);
    }
  };
};
