import { setResponseAction } from "../../utils/functions";
import { appActions } from "../appState";
import {
  getConsProjectService,
  getConsProjectsService,
  getConsProjectsStatsService,
  searchConsProjectsService,
} from "./projectsService";

export const getConsProjects = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await getConsProjectsService(query, token);
      if (
        res.response?.data.status === "fail" ||
        res.response?.data.status === "error"
      ) {
        dispatch(setResponseAction(res));
      } else {
        dispatch(appActions.setProjects({ projects: res.data }));
        dispatch(appActions.setCount({ count: res.count }));
      }
      dispatch(appActions.setIsFetching(false));
      dispatch(appActions.setFetchedProjects(true));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getConsProjectsStats = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      const res = await getConsProjectsStatsService(query, token);
      if (
        res.response?.data.status === "fail" ||
        res.response?.data.status === "error"
      ) {
        dispatch(setResponseAction(res));
      } else {
        dispatch(appActions.setProjectsStats(res.data));
      }
      dispatch(appActions.setFetchedProjectsStats(true));
    } catch (err) {
      console.log(err);
    }
  };
};

export const searchConsProjects = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(appActions.setIsFetching(true));
      const res = await searchConsProjectsService(query, token);
      if (
        res.response?.data.status === "fail" ||
        res.response?.data.status === "error"
      ) {
        dispatch(setResponseAction(res));
      } else {
        dispatch(appActions.setProjects({ projects: res.data }));
      }
      dispatch(appActions.setIsFetching(false));
      dispatch(appActions.setUpdateNeeded(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getProject = (projectId: string, token: string) => {
  return async (dispatch: any) => {
    try {
      const res = await getConsProjectService(projectId, token);
      dispatch(appActions.setProject(res.data));
    } catch (err) {
      console.log(err);
    }
  };
};
