import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState, useEffect } from "react";
import {
  Button,
  Box,
  CircularProgress,
  Divider,
  Tabs,
  Tab,
  Grid,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import SearchIcon from "@mui/icons-material/Search";
import InputComponent from "../Inputs/InputComponent";
import SelectComponent from "../Inputs/SelectComponent";
import DatePickerComponent from "../Inputs/datePicker";
import styles from "../../index.module.scss";
import { createProject } from "../../../store/app/appActions";
import { AppState, appActions } from "../../../store/appState";
import Storage from "../../../utils/Storage";
import { getClients } from "../../../store/clients/clientActions";

const TextWithLabel = (props: any) => (
  <Typography sx={{ padding: "5px 0px" }}>
    {props.label}:{" "}
    <span style={{ color: `&{props.color}`, fontWeight: "bolder" }}>
      {props.value}
    </span>
  </Typography>
);

export default function Newproduct(props: any) {
  const token = Storage.getToken();
  const [init, setInit] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [saving, setSaving] = useState(false);
  const [activeTab, setActiveTab] = useState(props.tabIndex || 0);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [category, setCategory] = useState("");
  const [partner, setPartner] = useState("");
  const nameRef = useRef<HTMLInputElement>(null);
  const measurementRef = useRef<HTMLInputElement>(null);
  const dateRef = useRef<HTMLInputElement>(null);
  const locationRef = useRef<HTMLInputElement>(null);
  const categories = useSelector((state: any) => state.app.categories);
  const clients = useSelector((state: any) => state.app.clients);
  const authState = useSelector((state: any) => state.auth);
  const appState: AppState = useSelector((state: any) => state.app);
  // const nameRef = useRef<HTMLInputElement>(null);

  var categoryValues = [];

  if (categories.length > 0) {
    categoryValues = categories.map((cat: any) => {
      return {
        name: cat.category,
        _id: cat._id,
      };
    });
  }

  const handleCategoryChange = (event: any) => {
    setCategory(event.target.value as string);
  };

  const handlePartnerChange = (event: any) => {
    setPartner(event.target.value as string);
  };

  useEffect(() => {
    if (init) {
      setFetching(true);
      dispatch(getClients("", token));
    }
    return () => {
      setInit(false);
    };
  }, [dispatch, init]);

  useEffect(() => {
    if (clients.length > 0 && categories.length > 0) {
      setFetching(false);
    }
  }, [dispatch, clients, categories]);

  const submit = async () => {
    dispatch(appActions.setFetching(true));
    setSubmitting(true);
    var data = {
      name: nameRef.current?.value,
      stock: category,
      startDate: dateRef.current?.value,
      location: locationRef.current?.value,
      measurement: measurementRef.current?.value,
      client: partner,
    };
    setSaving(true);
    var project = await createProject(data);
    // await createConsProject(project, data);
    setSaving(false);
    props.setOpen(false);
    dispatch(appActions.setUpdateNeeded(true));
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle className={`${styles.formHeader} ${styles.center}`}>
          <Typography className={styles.formHeaderText}>NEW PRODUCT</Typography>
        </DialogTitle> */}
        <DialogContent className={styles.formContent}>
          {fetching && (
            <Box className={styles.loadingContainer}>
              <CircularProgress />
            </Box>
          )}
          {!fetching && (
            <>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant="fullWidth"
                scrollButtons={false}
                aria-label="scrollable prevent tabs example"
              >
                <Tab label="Add Product" />
                <Tab label="Stock-In Product" />
              </Tabs>
              <Divider />
              {activeTab === 0 && (
                <Box>
                  <InputComponent
                    label="Product Name"
                    reference={nameRef}
                    icon={<SearchIcon />}
                    placeholder="eg: Siment , Fer a betont,..."
                  />
                  <InputComponent
                    label="Product Measurement"
                    reference={measurementRef}
                    icon={<SearchIcon />}
                    placeholder="eg: Kg, L, cm ..."
                  />
                  {categoryValues.length > 0 && (
                    <SelectComponent
                      label="Select Stock"
                      value={category}
                      values={appState.stock}
                      handleChange={handleCategoryChange}
                    />
                  )}
                  <Button variant="contained" sx={{ width: "50%" }}>
                    Save
                  </Button>
                </Box>
              )}

              {activeTab === 1 && (
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6} alignContent={"space-between"}>
                      <InputComponent
                        label="Quantity"
                        reference={locationRef}
                        icon={<SearchIcon />}
                        placeholder="How many products?"
                      />
                      <InputComponent
                        label="Price/Unit  : Rwf"
                        reference={locationRef}
                        icon={<SearchIcon />}
                        placeholder="Eg: 10000"
                      />
                      <DatePickerComponent
                        label="Stock-In Date"
                        icon={<SearchIcon />}
                        reference={dateRef}
                        placeholder="Pick Stock-In Date"
                      />

                      <InputComponent
                        label="Registered By"
                        reference={locationRef}
                        icon={<SearchIcon />}
                        defaultValue={authState?.user?.name}
                        placeholder="Added by "
                      />
                      {clients.length > 0 && (
                        <SelectComponent
                          label="Suplied By"
                          value={partner}
                          values={clients}
                          handleChange={handlePartnerChange}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Box>
                        <TextWithLabel
                          label="Stock"
                          value={"Stock 1"}
                          color="black"
                        />
                        <TextWithLabel
                          label="Product"
                          value={"Siment"}
                          color="black"
                        />
                        <TextWithLabel
                          label="Measurement"
                          value={"Sacs"}
                          color="black"
                        />
                        <TextWithLabel
                          label="Current QTY"
                          value={"12"}
                          color="black"
                        />
                      </Box>
                      <Button
                        variant="contained"
                        sx={{ width: "100%", margin: "10px 0px" }}
                      >
                        Add
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ width: "100%", margin: "10px 0px" }}
                      >
                        Close
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
