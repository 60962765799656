import Drawer from '@mui/material/Drawer';
import styles from './index.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import InputComponent from '../../components/UI/Inputs/InputComponent';

export default function ProjectView(props: any) {
  return (
    <Drawer
      PaperProps={{
        sx: { width: '40vw', padding: '2rem 1rem' },
      }}
      anchor={'right'}
      open={props.open}
      onClose={() => props.setOpen(false)}
    >
      <Box className={`${styles.aRight}`}>
        <CloseIcon onClick={() => props.setOpen(false)} className={`${styles.cPointer}`} />
      </Box>
      <Typography className={`${styles.header}`}>Project: IBIRAHURE KUMUYUMBU</Typography>
      <Typography>Project Client: Cedrick</Typography>
      <InputComponent label="PO Amount" placeholder="23,898,777" />
      <InputComponent label="Client" placeholder="23,898,777" />
    </Drawer>
  );
}
